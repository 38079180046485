<template>
  <router-view />
</template>

<script lang="ts" setup>
import { LogoutPostMessageHandler } from '@/modules/core/composables/PostMessage/handlers/LogoutPostMessageHandler';
import useMessageListener from '@/modules/core/composables/PostMessage/useMessageListener';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import { FolksSDK } from '@sdk/FolksSDK';
import { AuthenticationStoreCredentialsStorage } from '@/modules/authentication/store/AuthenticationStoreCredentialsStorage';

const authenticationStore = useAuthenticationStore();
const { addHandler } = useMessageListener({ name: 'app' });
addHandler('logout', new LogoutPostMessageHandler(authenticationStore));

FolksSDK.setCredentialsStorage(
  new AuthenticationStoreCredentialsStorage(authenticationStore)
);

FolksSDK.onRefreshTokenFail(async () => {
  return authenticationStore.logout();
});

const { t, locale } = useI18n();
watch(
  locale,
  () => {
    document.title = t('appTitle');
  },
  { immediate: true }
);
</script>
