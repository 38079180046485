import { defineStore } from 'pinia';
import type { Employee } from '@/modules/core/stores/employee.types';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import { useI18n } from 'vue-i18n';

export const useEmployeeStore = defineStore('employee', {
  state: () => ({
    employee: {} as Employee
  }),
  getters: {
    getEmployeePositionLocalized(state): string {
      if (!state.employee.position) {
        return '';
      }

      const { locale } = useI18n();
      const descriptionKey: 'description_fr' | 'description_en' =
        locale.value === SUPPORTED_LANGUAGES.en
          ? 'description_en'
          : 'description_fr';
      return state.employee.position[descriptionKey];
    }
  },
  actions: {
    formatEmployee(employeeData: Employee | null) {
      if (employeeData) {
        this.employee = employeeData;
      }
    }
  }
});
