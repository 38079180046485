<template>
  <FHeaderPrimary
    :back-route="ONBOARDING_ROUTES.ARRIVALS.ROOT"
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import ONBOARDING_ROUTES from '@/modules/onboarding/constants/urls';
import ONBOARDING_ABILITIES from '@/modules/onboarding/constants/abilities';
import type { Tab } from '@/components/FTabs.types';
import useFirstTabRedirect from '@/modules/core/composables/useFirstTabRedirect';

definePage({
  meta: {
    ability: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Configuration des arrivées et départs',
      tasks: 'Gestion des tâches',
      templates: 'Gestion des gabarits',
      titles: "Titre d'emploi / gabarit"
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Arrivals and Departures Configuration',
      tasks: 'Tasks',
      templates: 'Templates',
      titles: 'Job Title / Template'
    }
  }
});

const tabs = [
  {
    to: ONBOARDING_ROUTES.ARRIVALS.CONFIG.TASKS,
    ability: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.TASKS,
    title: t('tasks')
  },
  {
    to: ONBOARDING_ROUTES.ARRIVALS.CONFIG.TEMPLATES,
    ability: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.TEMPLATES,
    title: t('templates')
  },
  {
    to: ONBOARDING_ROUTES.ARRIVALS.CONFIG.TITLES,
    ability: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.TITLES,
    title: t('titles')
  }
] as Tab[];

useFirstTabRedirect(ONBOARDING_ROUTES.ARRIVALS.CONFIG.ROOT, tabs);
</script>
