import type { AuthenticationCredentialsStorageInterface } from '@sdk/modules/API/Interface/AuthenticationCredentialsStorageInterface';
import type { AuthenticationCredentials } from '@sdk/modules/authentication/interfaces/types/AuthenticationCredentials';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import type { AuthenticationSuccessfulResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/AuthenticationSuccessfulResponsePayload';

export class AuthenticationStoreCredentialsStorage
  implements AuthenticationCredentialsStorageInterface
{
  constructor(
    private authenticationStore: ReturnType<typeof useAuthenticationStore>
  ) {}

  async fetch(): Promise<AuthenticationCredentials> {
    this.authenticationStore.getFromLocalStorage();

    return this.getCredentialsFromStore();
  }

  async get(key: string): Promise<string> {
    const storeKey: string = this.toSnakeCase(key);
    if (storeKey in this.authenticationStore.authData) {
      return (
        this.authenticationStore.authData[
          storeKey as keyof AuthenticationSuccessfulResponsePayload
        ] + ''
      );
    }

    return '';
  }

  async save(credentials: AuthenticationCredentials): Promise<void> {
    await this.authenticationStore.saveCredentials(
      this.toStoreCredentials(credentials)
    );
  }

  async set(key: string, value: string): Promise<void> {
    const storeKey = this.toSnakeCase(
      key
    ) as keyof AuthenticationSuccessfulResponsePayload;

    if (storeKey in this.authenticationStore.authData) {
      (this.authenticationStore.authData as any)[storeKey] = value;
    }
  }

  private getCredentialsFromStore(): AuthenticationCredentials {
    return {
      accessToken: this.authenticationStore.authData.access_token,
      refreshToken: this.authenticationStore.authData.refresh_token
    };
  }

  private toSnakeCase(key: string): string {
    return key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }

  private toStoreCredentials(
    credentials: AuthenticationCredentials
  ): AuthenticationSuccessfulResponsePayload {
    return {
      access_token: credentials.accessToken,
      refresh_token: credentials.refreshToken,
      session_token: `${credentials.session_token}`,
      expires_in: parseInt(credentials.expires_in as string)
    };
  }
}
