const ONBOARDING_ABILITIES_ROOT = 'onboarding';
const CONFIG_ROOT = 'config';

const ARRIVALS_ABILITIES_ROOT = `${ONBOARDING_ABILITIES_ROOT}:arrivals`;
const ARRIVALS_ABILITIES_CONFIG_ROOT = `${ARRIVALS_ABILITIES_ROOT}:${CONFIG_ROOT}`;
const DEPARTURES_ABILITIES_ROOT = `${ONBOARDING_ABILITIES_ROOT}:departures`;
const DEPARTURES_ABILITIES_CONFIG_ROOT = `${DEPARTURES_ABILITIES_ROOT}:${CONFIG_ROOT}`;

const ONBOARDING_ABILITIES = {
  ANY: ONBOARDING_ABILITIES_ROOT,
  ARRIVALS: {
    ANY: ARRIVALS_ABILITIES_ROOT,
    LIST: `${ARRIVALS_ABILITIES_ROOT}:list`,
    CONFIG: {
      ANY: ARRIVALS_ABILITIES_CONFIG_ROOT,
      TASKS: `${ARRIVALS_ABILITIES_CONFIG_ROOT}:tasks`,
      TEMPLATES: `${ARRIVALS_ABILITIES_CONFIG_ROOT}:templates`,
      TITLES: `${ARRIVALS_ABILITIES_CONFIG_ROOT}:titles`
    }
  },
  DEPARTURES: {
    ANY: DEPARTURES_ABILITIES_ROOT,
    LIST: `${DEPARTURES_ABILITIES_ROOT}:list`,
    CONTROL: `${DEPARTURES_ABILITIES_ROOT}:control`,
    CONFIG: {
      ANY: DEPARTURES_ABILITIES_CONFIG_ROOT,
      TASKS: `${DEPARTURES_ABILITIES_CONFIG_ROOT}:tasks`,
      TEMPLATES: `${DEPARTURES_ABILITIES_CONFIG_ROOT}:templates`,
      TITLES: `${DEPARTURES_ABILITIES_CONFIG_ROOT}:titles`
    }
  }
};

export default ONBOARDING_ABILITIES;
