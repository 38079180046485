import PARAMETERS_ABILITIES_ROOT from '@/modules/parameters/constants/abilities';

const ROLES_PERMISSIONS_ROOT = `${PARAMETERS_ABILITIES_ROOT}:roles-permissions`;

const ROLES_PERMISSIONS_ABILITIES = {
  ANY: ROLES_PERMISSIONS_ROOT,
  LIST: `${ROLES_PERMISSIONS_ROOT}:list`,
  CREATE: `${ROLES_PERMISSIONS_ROOT}:create`,
  EDIT: `${ROLES_PERMISSIONS_ROOT}:edit`
};

export default ROLES_PERMISSIONS_ABILITIES;
