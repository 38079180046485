<template>
  <router-view :key="$route.fullPath" />
</template>
<script lang="ts" setup>
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.ANY
  }
});
</script>
