import type { BrowserConfig } from '@bugsnag/js';
import Bugsnag from '@bugsnag/js';
import type { BugsnagPluginVueResult } from '@bugsnag/plugin-vue';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import type { BrowserConfiguration } from '@bugsnag/browser-performance';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { VueRouterRoutingProvider } from '@bugsnag/vue-router-performance';
import type { Router } from 'vue-router';
import { useCompanyStore } from '@/modules/core/stores/compagny';
import { useEmployeeStore } from '@/modules/core/stores/employee';
import { useUserStore } from '@/modules/core/stores/user';
import { storeToRefs } from 'pinia';

const bugsnagOptions: BrowserConfig = {
  enabledReleaseStages: import.meta.env.VITE_APP_BUGSNAG_REPORT_STAGES.split(
    ','
  ),
  releaseStage: import.meta.env.VITE_APP_BUGSNAG_STAGE,
  apiKey: import.meta.env.VITE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user']
};
const bugsnagPerformanceOptions: BrowserConfiguration = {
  enabledReleaseStages: import.meta.env.VITE_APP_BUGSNAG_REPORT_STAGES.split(
    ','
  ),
  releaseStage: import.meta.env.VITE_APP_BUGSNAG_STAGE,
  apiKey: import.meta.env.VITE_APP_BUGSNAG_API_KEY
};
if ('VITE_APP_VERSION' in import.meta.env) {
  bugsnagOptions.appVersion = import.meta.env.VITE_APP_VERSION;
  bugsnagPerformanceOptions.appVersion = import.meta.env.VITE_APP_VERSION;
}

export const bugsnagStartAndGetVue = (): BugsnagPluginVueResult => {
  Bugsnag.start(bugsnagOptions);
  return Bugsnag.getPlugin('vue') as BugsnagPluginVueResult;
};
export const bugsnagPerformanceStart = (router: Router) => {
  bugsnagPerformanceOptions.routingProvider = new VueRouterRoutingProvider(
    router,
    import.meta.env.BASE_URL
  );
  BugsnagPerformance.start(bugsnagPerformanceOptions);
};

export const fillBugsnag = () => {
  const userStore = useUserStore();
  Bugsnag.setUser(userStore.id.toString());
  const companyStore = useCompanyStore();
  if (companyStore.company.id !== null) {
    Bugsnag.addMetadata('company', {
      id: companyStore.company.id.toString()
    });
  }

  const { employee } = storeToRefs(useEmployeeStore());
  if ('id' in employee.value) {
    Bugsnag.addMetadata('employee', {
      id: employee.value.id.toString()
    });
  }
};
