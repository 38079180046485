import PARAMETERS_ABILITIES_ROOT from '@/modules/parameters/constants/abilities';
const USERS_ROOT = `${PARAMETERS_ABILITIES_ROOT}:users`;

const USERS_ABILITIES = {
  ANY: USERS_ROOT,
  LIST: `${USERS_ROOT}:list`,
  CREATE: `${USERS_ROOT}:create`,
  EDIT: `${USERS_ROOT}:edit`,
  BULK: `${USERS_ROOT}:bulk`
};

export default USERS_ABILITIES;
