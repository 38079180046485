<template>
  <FHeaderPrimary
    :configuration-ability="EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.CONFIG.ANY"
    :configuration-route="EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.CONFIG.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.LIST,
    bypassAbility: [EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.CONFIG.ANY]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Compte de dépense',
      editModalTitle: "Détails d'une demande de compte de dépense"
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Expense account',
      editModalTitle: 'Expense account request details'
    }
  }
});
</script>
