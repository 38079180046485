<template>
  <FLayoutDefault>
    <router-view :key="$route.fullPath" />
  </FLayoutDefault>
</template>
<script lang="ts" setup>
import REPORTS_ABILITIES from '@/modules/reports/constants/abilities';

definePage({
  meta: {
    needAuth: true,
    ability: REPORTS_ABILITIES.ANY
  }
});
</script>
<style lang="sass" scoped></style>
