<template>
  <FHeaderPrimary
    :add-ability="TIME_MANAGEMENT_ABILITIES.OVERTIME.CONTROL"
    :title="t(`time-management.overtime.title`)"
    @add:click="handleAddClick"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import CONTROL_TYPES from '@/modules/core/constants/controlTypes';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.OVERTIME.LIST,
    bypassAbility: [TIME_MANAGEMENT_ABILITIES.OVERTIME.CONTROL]
  }
});

const { t } = useI18n({
  useScope: 'global'
});

const { handleAddClick } = useTimeManagementList(CONTROL_TYPES.OVERTIME);
</script>
<style lang="sass" scoped></style>
