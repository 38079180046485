<template>
  <FLayoutAuth>
    <router-view />
  </FLayoutAuth>
</template>
<script lang="ts" setup>
definePage({
  meta: {
    isAuthPages: true
  }
});
</script>
<style lang="sass" scoped></style>
