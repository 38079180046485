export default {
  VAppBar: {
    flat: true
  },
  VAutocomplete: {
    variant: 'filled'
  },
  VBanner: {
    color: 'primary'
  },
  VBottomSheet: {
    contentClass: 'rounded-t-xl overflow-hidden'
  },
  VBtn: {
    class: 'text-label-large',
    variant: 'flat',
    color: 'primary-variant'
  },
  VBtnGroup: {
    rounded: 'xl',
    VBtn: {
      rounded: null
    }
  },
  VCard: {
    rounded: 'lg',
    VCardActions: {
      VBtn: {
        variant: 'flat',
        slim: false
      }
    }
  },
  VCheckbox: {
    color: 'secondary',
    inset: true
  },
  VChip: {
    rounded: 'sm'
  },
  VCombobox: {
    variant: 'filled'
  },
  VMenu: {
    minWidth: 112,
    maxWidth: 280
  },
  VNavigationDrawer: {
    color: 'surface-container-low',
    VList: {
      nav: true
    }
  },
  VListItem: {},
  VSelect: {
    variant: 'filled'
  },
  VSlider: {
    color: 'primary'
  },
  VTabs: {
    color: 'primary'
  },
  VTextarea: {
    variant: 'filled'
  },
  VTextField: {
    variant: 'filled',
    hideDetails: 'auto'
  },
  VToolbar: {
    VBtn: {
      color: null
    }
  }
};
