import { defineStore } from 'pinia';
import { get } from '@/modules/core/services/featureFlags';
import type {
  FeatureFlag,
  FeatureFlagService
} from '@/modules/core/stores/featureFlags.types';

function normalizeFlag(flag: string) {
  const flagParts = flag.split(':');
  const normalizedFlag =
    flagParts.length > 1 ? flagParts.slice(1).join('.') : flagParts[0];
  const module = flagParts.length > 1 ? flagParts[0] : 'core';

  return [module, normalizedFlag];
}

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: (): {
    featureFlags: FeatureFlag[];
  } => ({
    featureFlags: []
  }),
  getters: {
    getFlag: (state) => {
      return (flagName: string) => {
        const [module, key] = normalizeFlag(flagName);
        const foundFlag = state.featureFlags.find(
          (flag) => flag.key === key && flag.module === module
        );

        if (!foundFlag) {
          throw new Error(`Flag ${flagName} not found`);
        }

        return foundFlag;
      };
    }
  },
  actions: {
    async getFeatureFlags() {
      const {
        data: { data: flags }
      }: { data: { data: FeatureFlagService[] } } = await get();
      this.featureFlags = flags.map((flag) => {
        const [module, key] = normalizeFlag(flag.key);
        return {
          key,
          isActive: flag.active,
          value: flag.value,
          module
        } as FeatureFlag;
      });
    }
  }
});
