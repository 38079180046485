const BASE_ROUTE_HIRING = '/hiring';
const BASE_ROUTE_REQUISITION = `${BASE_ROUTE_HIRING}/requisition`;
const BASE_ROUTE_REQUISITION_CONFIG = `${BASE_ROUTE_REQUISITION}/configurations`;

const HIRING_ROUTES = {
  ROOT: BASE_ROUTE_HIRING,
  REQUISITIONS: {
    ROOT: `${BASE_ROUTE_REQUISITION}`,
    CREATE: `${BASE_ROUTE_REQUISITION}/create`,
    EDIT: `${BASE_ROUTE_REQUISITION}/edit`,
    CONFIG: {
      ROOT: BASE_ROUTE_REQUISITION_CONFIG,
      EDIT: `${BASE_ROUTE_REQUISITION_CONFIG}/config`
    }
  }
};

export default HIRING_ROUTES;
