<template>
  <FLayoutDefault>
    <FErrorPageContent
      :sub-title="t('subTitle')"
      :text="t('text')"
      :title="t('title')"
    />
  </FLayoutDefault>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';

definePage({
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: '404',
      subTitle: 'Oups, page introuvable...',
      text: "Il semble que la page que vous cherchez n'existe pas ou a été déplacée."
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: '404',
      subTitle: 'Oops, page not found...',
      text: 'It seems the page you are looking for does not exist or has been moved.'
    }
  }
});
</script>
