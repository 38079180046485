<template>
  <FHeaderPrimary
    :configuration-ability="TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.ANY"
    :configuration-route="TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.VIEW,
    bypassAbility: [TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.ANY]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Feuilles de temps'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Timesheets'
    }
  }
});
</script>
<style lang="sass" scoped></style>
