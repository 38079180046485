const HIRING_ROOT = 'hiring';
const CONFIG_ROOT = 'config';

const REQUISITION_ROOT = `${HIRING_ROOT}:requisition`;
const REQUISITION_CONFIG_ROOT = `${REQUISITION_ROOT}:${CONFIG_ROOT}`;
const ATS_ROOT = `${HIRING_ROOT}:ats`;

const HIRING_ABILITIES = {
  ANY: `${HIRING_ROOT}`,
  REQUISITIONS: {
    ANY: `${REQUISITION_ROOT}`,
    LIST: `${REQUISITION_ROOT}:analysis`,
    REQUEST: `${REQUISITION_ROOT}:request`,
    CONFIG: {
      ANY: REQUISITION_CONFIG_ROOT,
      EDIT: `${REQUISITION_CONFIG_ROOT}:edit`
    }
  },
  ATS: {
    ANY: `${ATS_ROOT}`,
    EXTERNAL_LINK: `${ATS_ROOT}:external-link`
  }
};

export default HIRING_ABILITIES;
