<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import ADVANCEMENT_ABILITIES from '@/modules/advancement/constants/abilities';

definePage({
  meta: {
    ability: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.CERTIFICATES
  }
});
</script>
