import { logout } from '../services/authentification';
import { defineStore } from 'pinia';
import localStorageWithExpiration from '@/modules/core/utils/localStorageWithExpiration';
import router from '@/plugins/vueRouter';
import type { AuthenticationSuccessfulResponsePayload } from '@sdk/modules/authentication/interfaces/types/responses/AuthenticationSuccessfulResponsePayload';
import useSDKContainer from '@/modules/core/composables/useSDKContainer';
import type { AuthenticationServiceInterface } from '@sdk/modules/authentication/interfaces/AuthenticationServiceInterface';
import { AUTHENTICATION_BINDING_TYPES } from '@sdk/modules/authentication/FolksSDKAuthenticationModuleTypes';
import proxy from '@/modules/core/utils/apiFolks/proxy';
import { AuthenticationService } from '@sdk/modules/authentication';
import AUTHENTICATION_ROUTES from '@/modules/authentication/constants/urls';

export const useAuthenticationStore = defineStore('authentication', {
  state: () => ({
    authData: {
      access_token: '',
      session_token: '',
      refresh_token: '',
      expires_in: 0
    } as AuthenticationSuccessfulResponsePayload
  }),
  actions: {
    async login(
      email: string,
      password: string,
      authenticationCode: string | null = null
    ) {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationServiceInterface>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      return await authenticationService.authenticate(
        email,
        password,
        authenticationCode
      );
    },
    async saveCredentials(
      authenticationPayload: AuthenticationSuccessfulResponsePayload
    ) {
      this.authData = authenticationPayload;
      localStorageWithExpiration.setItem(
        'authData',
        this.authData,
        this.authData.expires_in * 1000
      );

      // todo-ndamours: remove when we only use SDK's axios.
      proxy.defaults.headers['Authorization'] =
        `Bearer ${this.authData.access_token}`;
    },
    async resend(email: string, password: string) {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationServiceInterface>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      return await authenticationService.resendChallenge(email, password);
    },
    async refreshToken() {
      const { resolve } = useSDKContainer();
      const authenticationService = resolve<AuthenticationService>(
        AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
      );

      const response = await authenticationService.refresh();
      this.authData = response.getPayload();

      localStorageWithExpiration.setItem(
        'authData',
        this.authData,
        this.authData.expires_in * 1000
      );
    },
    getFromLocalStorage() {
      const authData = localStorageWithExpiration.getItem('authData');
      if (authData) {
        this.authData = authData;
      }
    },
    async logout() {
      await logout();
      this.authData = {
        access_token: '',
        session_token: '',
        refresh_token: '',
        expires_in: 0
      };
      localStorageWithExpiration.removeItem('authData');
      await router.push(AUTHENTICATION_ROUTES.LOGIN);
    }
  }
});
