/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */
import 'reflect-metadata';

// Plugins
import { registerPlugins } from '@/plugins';
import { registerAllModules } from '@/modules';
// Components
import App from './App.vue';
// Composables
import { createApp } from 'vue';
import { FolksSDK } from '@sdk/index';
import TestIdDirective from '@/modules/core/directives/TestIdDirective';
import vDynamicDataAttributeDirective from '@/modules/core/directives/DynamicDataAttributeDirective';

// Folks SDK init
FolksSDK.initialize({
  api: {
    baseUrl: import.meta.env.VITE_APP_API_URL_ORIGIN + '/api/v2/'
  }
});
const app = createApp(App);
registerPlugins(app);
app.directive('test-id', TestIdDirective);
app.directive('dynamic-data-attr', vDynamicDataAttributeDirective);
app.mount('#app');

registerAllModules();
