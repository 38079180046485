import type { Program } from '@/modules/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/core/constants/legacyType';
import ADVANCEMENT_ABILITIES from '@/modules/advancement/constants/abilities';
import ADVANCEMENT_ROUTES from '@/modules/advancement/constants/urls';

const CERTIFICATES_CAREERS_MAPPING = [
  {
    name: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.CONTROL,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 205540,
    module: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.ANY,
    routeName: ADVANCEMENT_ROUTES.CERTIFICATES_CAREERS.CONTROL
  },
  {
    name: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.CERTIFICATES,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 205530,
    module: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.ANY,
    routeName: ADVANCEMENT_ROUTES.CERTIFICATES_CAREERS.CERTIFICATES
  },
  {
    name: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.CAREERS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 205510,
    module: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.ANY,
    routeName: ADVANCEMENT_ROUTES.CERTIFICATES_CAREERS.CAREERS
  }
] as Program[];

const TRAINING_MAPPING = [
  {
    name: ADVANCEMENT_ABILITIES.TRAINING.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202010,
    module: ADVANCEMENT_ABILITIES.TRAINING.ANY,
    routeName: ADVANCEMENT_ROUTES.TRAINING.ROOT
  },
  {
    name: ADVANCEMENT_ABILITIES.TRAINING.CONTROL + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202030,
    editKey: 'edit_field',
    module: ADVANCEMENT_ABILITIES.TRAINING.ANY,
    routeName: ADVANCEMENT_ROUTES.TRAINING.CONTROL_EDIT
  },
  {
    name: ADVANCEMENT_ABILITIES.TRAINING.CONTROL + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202030,
    module: ADVANCEMENT_ABILITIES.TRAINING.ANY,
    routeName: ADVANCEMENT_ROUTES.TRAINING.CONTROL
  },
  {
    name: ADVANCEMENT_ABILITIES.TRAINING.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 202020,
    editKey: 'edit_field',
    module: ADVANCEMENT_ABILITIES.TRAINING.ANY,
    routeName: ADVANCEMENT_ROUTES.TRAINING.EDIT
  },
  {
    name: ADVANCEMENT_ABILITIES.TRAINING.REQUEST + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 404000,
    module: ADVANCEMENT_ABILITIES.TRAINING.ANY,
    routeName: ADVANCEMENT_ROUTES.TRAINING.CREATE
  }
] as Program[];

const PERFORMANCES_MAPPING = [
  {
    name: ADVANCEMENT_ABILITIES.PERFORMANCES.SUMMARY,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 203540,
    module: ADVANCEMENT_ABILITIES.PERFORMANCES.ANY,
    routeName: ADVANCEMENT_ROUTES.PERFORMANCES.SUMMARY,
    iframeUrl: '/legacy/HrManagement/evaluation/employee/summary/'
  },
  {
    name: ADVANCEMENT_ABILITIES.PERFORMANCES.TRACKING,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 203530,
    module: ADVANCEMENT_ABILITIES.PERFORMANCES.ANY,
    routeName: ADVANCEMENT_ROUTES.PERFORMANCES.TRACKING,
    iframeUrl: '/legacy/HrManagement/evaluation/employee/monitoring/'
  },
  {
    name: ADVANCEMENT_ABILITIES.PERFORMANCES.ANALYSIS,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 203510,
    module: ADVANCEMENT_ABILITIES.PERFORMANCES.ANY,
    routeName: ADVANCEMENT_ROUTES.PERFORMANCES.ANALYSIS
  },
  {
    name: ADVANCEMENT_ABILITIES.PERFORMANCES.ADMIN,
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 505060,
    module: ADVANCEMENT_ABILITIES.PERFORMANCES.ANY,
    routeName: ADVANCEMENT_ROUTES.PERFORMANCES.ADMIN,
    iframeUrl: '/legacy/HrManagement/evaluation/admin/template/list/'
  }
] as Program[];

const ADVANCEMENT_MAPPING = [
  ...CERTIFICATES_CAREERS_MAPPING,
  ...TRAINING_MAPPING,
  ...PERFORMANCES_MAPPING
] as Program[];

export default ADVANCEMENT_MAPPING;
