const ADVANCEMENT_ROOT = 'advancement';

const PERFORMANCES_ROOT = `${ADVANCEMENT_ROOT}:performances`;
const CERTIFICATES_ROOT = `${ADVANCEMENT_ROOT}:certificates-careers`;
const TRAINING_ROOT = `${ADVANCEMENT_ROOT}:training`;

const ADVANCEMENT_ABILITIES = {
  ANY: `${ADVANCEMENT_ROOT}`,
  PERFORMANCES: {
    ANY: `${PERFORMANCES_ROOT}`,
    SUMMARY: `${PERFORMANCES_ROOT}:summary`,
    TRACKING: `${PERFORMANCES_ROOT}:tracking`,
    ANALYSIS: `${PERFORMANCES_ROOT}:analysis`,
    ADMIN: `${PERFORMANCES_ROOT}:admin`
  },
  CERTIFICATES_CAREERS: {
    ANY: `${CERTIFICATES_ROOT}`,
    CONTROL: `${CERTIFICATES_ROOT}:control`,
    CAREERS: `${CERTIFICATES_ROOT}:careers`,
    CERTIFICATES: `${CERTIFICATES_ROOT}:certificates`
  },
  TRAINING: {
    ANY: `${TRAINING_ROOT}`,
    LIST: `${TRAINING_ROOT}:analysis`,
    REQUEST: `${TRAINING_ROOT}:request`,
    CONTROL: `${TRAINING_ROOT}:control`
  }
};

export default ADVANCEMENT_ABILITIES;
