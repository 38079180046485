const BASE_ROUTE_BASE = '/';

const BASE_ROUTES = {
  HOME: BASE_ROUTE_BASE,
  DOCUMENTARY_VOUT: `${BASE_ROUTE_BASE}documentary-vout`,
  EMPLOYEE_DIRECTORY: `${BASE_ROUTE_BASE}employee-directory`,
  SIMULATOR: `${BASE_ROUTE_BASE}simulator`,
  UNAUTHORIZED: `${BASE_ROUTE_BASE}unauthorized`,
  USER_PARAMS: `${BASE_ROUTE_BASE}user-params`
};

export default BASE_ROUTES;
