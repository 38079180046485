export const lightFolksVariables = {
  'high-emphasis-opacity': 1,
  'medium-emphasis-opacity': 1,
  'hover-opacity': 0.08
};
export const darkFolksVariables = {
  'high-emphasis-opacity': 1,
  'medium-emphasis-opacity': 1,
  'hover-opacity': 0.08
};
