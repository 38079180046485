import BASE_ROUTE_PARAMETERS from '@/modules/parameters/constants/urls';

const BASE_ROUTE_SYSTEM_MANAGEMENT = `${BASE_ROUTE_PARAMETERS}/system-management`;

const BASE_ROUTE_COMPANIES = `${BASE_ROUTE_SYSTEM_MANAGEMENT}/companies`;
const BASE_ROUTE_TIME_MANAGEMENT = `${BASE_ROUTE_SYSTEM_MANAGEMENT}/time-management`;

const SYSTEM_MANAGEMENT_ROUTES = {
  ROOT: BASE_ROUTE_SYSTEM_MANAGEMENT,
  COMPANIES: {
    ROOT: BASE_ROUTE_COMPANIES,
    LIST: BASE_ROUTE_COMPANIES,
    CREATE: `${BASE_ROUTE_COMPANIES}/create`,
    EDIT: `${BASE_ROUTE_COMPANIES}/edit`,
    CONFIG: `${BASE_ROUTE_COMPANIES}/config`
  },
  ACTIVE_EMPLOYEES: {
    ROOT: `${BASE_ROUTE_SYSTEM_MANAGEMENT}/active-employees`
  },
  PW_NUMBERS: {
    ROOT: `${BASE_ROUTE_SYSTEM_MANAGEMENT}/pw-numbers`
  },
  TIME_MANAGEMENT: {
    ROOT: BASE_ROUTE_TIME_MANAGEMENT,
    ABSENCES_REASONS: `${BASE_ROUTE_TIME_MANAGEMENT}/absences-reasons`,
    VACATION_TYPES: `${BASE_ROUTE_TIME_MANAGEMENT}/vacation-types`,
    OVERTIME_TYPES: `${BASE_ROUTE_TIME_MANAGEMENT}/overtime-types`,
    OVERTIME_VALUES: `${BASE_ROUTE_TIME_MANAGEMENT}/overtime-values`
  }
};

export default SYSTEM_MANAGEMENT_ROUTES;
