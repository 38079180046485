import LEGACY_TYPE from '@/modules/core/constants/legacyType';
import type { Program } from '@/modules/core/utils/mapping/index.types';
import USERS_ABILITIES from '@/modules/parameters/submodules/users/constants/abilities';
import USERS_ROUTES from '@/modules/parameters/submodules/users/constants/urls';

const USERS_MAPPING = [
  {
    name: USERS_ABILITIES.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 502010,
    module: USERS_ABILITIES.ANY,
    routeName: USERS_ROUTES.ROOT
  },
  {
    name: USERS_ABILITIES.EDIT,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 502020,
    editKey: 'id',
    module: USERS_ABILITIES.ANY,
    routeName: USERS_ROUTES.EDIT
  },
  {
    name: USERS_ABILITIES.CREATE,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 502020,
    module: USERS_ABILITIES.ANY,
    routeName: USERS_ROUTES.CREATE
  },
  {
    name: USERS_ABILITIES.BULK,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 509001,
    module: USERS_ABILITIES.ANY,
    routeName: USERS_ROUTES.BULK
  }
] as Program[];

export default USERS_MAPPING;
