<template>
  <FLayoutDefault>
    <FErrorPageContent
      :sub-title="t('subTitle')"
      :text="t('text')"
      :title="t('title')"
    />
  </FLayoutDefault>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';

definePage({
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: '403',
      subTitle: 'Accès refusé',
      text: "Il semble que vous n'avez pas les autorisations nécessaires pour accéder à cette page."
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: '403',
      subTitle: 'Access denied',
      text: 'It seems you do not have the necessary permissions to access this page.'
    }
  }
});
</script>
