import type { Program } from '@/modules/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/core/constants/legacyType';
import ROLES_PERMISSIONS_ABILITIES from '@/modules/parameters/submodules/roles-permissions/constants/abilities';
import ROLES_PERMISSIONS_ROUTES from '@/modules/parameters/submodules/roles-permissions/constants/urls';

const ROLES_PERMISSIONS_MAPPING = [
  {
    name: ROLES_PERMISSIONS_ABILITIES.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 502030,
    module: ROLES_PERMISSIONS_ABILITIES.ANY,
    routeName: ROLES_PERMISSIONS_ROUTES.ROOT
  }
] as Program[];

export default ROLES_PERMISSIONS_MAPPING;
