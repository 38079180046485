const BASE_ROUTE_EMPLOYEES = '/employees';
const BASE_ROUTE_EMPLOYEE = `${BASE_ROUTE_EMPLOYEES}/employee/[id]`;
const BASE_ROUTE_EXPENSE_ACCOUNTS = `${BASE_ROUTE_EMPLOYEES}/expense-accounts`;
const BASE_ROUTE_EXPENSE_ACCOUNT_CONFIG = `${BASE_ROUTE_EXPENSE_ACCOUNTS}/configurations`;

const EMPLOYEES_ROUTES = {
  ROOT: BASE_ROUTE_EMPLOYEES,
  LIST: `${BASE_ROUTE_EMPLOYEES}/list`,
  EMPLOYEE: {
    ROOT: BASE_ROUTE_EMPLOYEE,
    NOTE: `${BASE_ROUTE_EMPLOYEE}/note`,
    ONBOARDING: `${BASE_ROUTE_EMPLOYEE}/onboarding`
  },
  EXPENSE_ACCOUNTS: {
    ROOT: BASE_ROUTE_EXPENSE_ACCOUNTS,
    CREATE: `${BASE_ROUTE_EXPENSE_ACCOUNTS}/create`,
    EDIT: `${BASE_ROUTE_EXPENSE_ACCOUNTS}/edit`,
    CONFIG: {
      ROOT: BASE_ROUTE_EXPENSE_ACCOUNT_CONFIG
    }
  }
};

export default EMPLOYEES_ROUTES;
