const SAFETY_ROOT = 'safety';

const INCIDENTS_ROOT = `${SAFETY_ROOT}:incidents`;

const SAFETY_ABILITIES = {
  ANY: `${SAFETY_ROOT}`,
  INCIDENTS: {
    ANY: `${INCIDENTS_ROOT}`
  }
};

export default SAFETY_ABILITIES;
