const DOCUMENTS_ABILITIES_ROOT = 'documents';
const CONFIG_ROOT = 'config';

const VAULT_ROOT = `${DOCUMENTS_ABILITIES_ROOT}:vault`;
const VAULT_CONFIG_ROOT = `${VAULT_ROOT}:${CONFIG_ROOT}`;
const E_SIGN_ROOT = `${DOCUMENTS_ABILITIES_ROOT}:e-sign`;

const DOCUMENTS_ABILITIES = {
  ANY: DOCUMENTS_ABILITIES_ROOT,
  VAULT: {
    ANY: VAULT_ROOT,
    CONFIG: {
      ANY: VAULT_CONFIG_ROOT
    }
  },
  E_SIGN: {
    ANY: E_SIGN_ROOT
  }
};

export default DOCUMENTS_ABILITIES;
