const BASE_ROUTE_ONBOARDING = '/onboarding';

const BASE_ROUTE_ARRIVALS = `${BASE_ROUTE_ONBOARDING}/arrivals`;
const BASE_ROUTE_ARRIVALS_CONFIG = `${BASE_ROUTE_ARRIVALS}/configurations`;
const BASE_ROUTE_DEPARTURES = `${BASE_ROUTE_ONBOARDING}/departures`;
const BASE_ROUTE_DEPARTURES_CONFIG = `${BASE_ROUTE_DEPARTURES}/configurations`;

const ONBOARDING_ROUTES = {
  ROOT: BASE_ROUTE_ONBOARDING,
  ARRIVALS: {
    ROOT: BASE_ROUTE_ARRIVALS,
    CONFIG: {
      ROOT: BASE_ROUTE_ARRIVALS_CONFIG,
      TASKS: `${BASE_ROUTE_ARRIVALS_CONFIG}/tasks`,
      TEMPLATES: `${BASE_ROUTE_ARRIVALS_CONFIG}/templates`,
      TITLES: `${BASE_ROUTE_ARRIVALS_CONFIG}/titles`
    }
  },
  DEPARTURES: {
    ROOT: BASE_ROUTE_DEPARTURES,
    CONTROL: `${BASE_ROUTE_DEPARTURES}/control`,
    CONFIG: {
      ROOT: BASE_ROUTE_DEPARTURES_CONFIG,
      TASKS: `${BASE_ROUTE_DEPARTURES_CONFIG}/tasks`,
      TEMPLATES: `${BASE_ROUTE_DEPARTURES_CONFIG}/templates`,
      TITLES: `${BASE_ROUTE_DEPARTURES_CONFIG}/titles`
    }
  }
};

export default ONBOARDING_ROUTES;
