import { createVuetify } from 'vuetify';
import { md } from 'vuetify/iconsets/md';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

import { darkFolksTheme, lightFolksTheme } from './vuetify-folks-themes';
import {
  darkFolksVariables,
  lightFolksVariables
} from '@/plugins/vuetify-folks-variables';
import folksDefaults from './vuetify-folks-defaults';
import m3Defaults from './vuetify-m3-defaults';

import { ObjectUtils } from '@sdk/modules/core';

import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'material-symbols/index.css';
import 'vuetify/styles';

import '../styles/md3/index.scss';
import '../styles/folks/index.scss';

export default createVuetify({
  display: {
    mobileBreakpoint: 'sm'
  },
  theme: {
    defaultTheme: 'lightFolksTheme',
    themes: {
      lightFolksTheme: { ...lightFolksTheme, variables: lightFolksVariables },
      darkFolksTheme: { ...darkFolksTheme, variables: darkFolksVariables }
    },
    variations: {
      colors: ['primary', 'secondary', 'tertiary'],
      darken: 10,
      lighten: 10
    }
  },
  icons: {
    aliases,
    defaultSet: 'mdi',
    sets: {
      mdi,
      md
    }
  },
  defaults: {
    ...ObjectUtils.mergeDeep({}, m3Defaults, folksDefaults)
  }
});
