const BASE_ROUTE_ADVANCEMENT = '/advancement';

const BASE_ROUTE_PERFORMANCES = `${BASE_ROUTE_ADVANCEMENT}/performances`;
const BASE_ROUTE_CERTIFICATES = `${BASE_ROUTE_ADVANCEMENT}/certificates-careers`;
const BASE_ROUTE_TRAINING = `${BASE_ROUTE_ADVANCEMENT}/training`;

const ADVANCEMENT_ROUTES = {
  ROOT: BASE_ROUTE_ADVANCEMENT,
  PERFORMANCES: {
    ROOT: BASE_ROUTE_PERFORMANCES,
    SUMMARY: `${BASE_ROUTE_PERFORMANCES}/summary`,
    TRACKING: `${BASE_ROUTE_PERFORMANCES}/tracking`,
    ANALYSIS: `${BASE_ROUTE_PERFORMANCES}/analysis`,
    ADMIN: `${BASE_ROUTE_PERFORMANCES}/admin`
  },
  CERTIFICATES_CAREERS: {
    ROOT: BASE_ROUTE_CERTIFICATES,
    CONTROL: `${BASE_ROUTE_CERTIFICATES}/control`,
    CAREERS: `${BASE_ROUTE_CERTIFICATES}/careers`,
    CERTIFICATES: `${BASE_ROUTE_CERTIFICATES}/certificates`
  },
  TRAINING: {
    ROOT: BASE_ROUTE_TRAINING,
    CREATE: `${BASE_ROUTE_TRAINING}/request`,
    EDIT: `${BASE_ROUTE_TRAINING}/request/:edit_field`,
    CONTROL: `${BASE_ROUTE_TRAINING}/control`,
    CONTROL_EDIT: `${BASE_ROUTE_TRAINING}/control/:edit_field`
  }
};

export default ADVANCEMENT_ROUTES;
