const BASE_ROOT = 'base';

const BASE_ABILITIES = {
  HOME: `${BASE_ROOT}:home`,
  EMPLOYEE_DIRECTORY: `${BASE_ROOT}:employee-directory`,
  DOCUMENTARY_VOUT: `${BASE_ROOT}:documentary-vout`,
  USER_PARAMS: `${BASE_ROOT}:user-params`
};

export default BASE_ABILITIES;
