import { MessageEventData } from '@/modules/core/objects/MessageEventData';
import useSettings from '@/modules/core/composables/useSettings';
import useDebugLogging from '@/modules/core/composables/useDebugLogging';
import type { PostMessageHandlerInterface } from '@/modules/core/composables/PostMessage/handlers/PostMessageHandlerInterface';
import useMessageSender from '@/modules/core/composables/PostMessage/useMessageSender';
import { getProgramDataByIframeRoute } from '@/modules/core/utils/mapping';
import type {
  AddHandler,
  UseMessageListenerParams
} from './useMessageListener.types';

export default function useMessageListener(
  {
    name = 'unknown',
    allowedPathsRegexp = [],
    allowedPageID = 0
  }: UseMessageListenerParams = {
    name: 'unknown',
    allowedPathsRegexp: [],
    allowedPageID: 0
  }
) {
  const isParentPathValid = (eventPath: string) => {
    if (allowedPathsRegexp.length === 0 && allowedPageID === 0) {
      return true;
    }

    if (allowedPageID !== 0) {
      return eventPath.includes(`${allowedPageID.toString()}`);
    }

    return allowedPathsRegexp.some((regexp) => regexp.test(eventPath));
  };

  const getAuthorizedOrigins = () => {
    const { getSetting } = useSettings();
    return getSetting('postMessageAuthorizedOrigins');
  };

  const isOriginValid = (origin: string) => {
    return Object.values(getAuthorizedOrigins()).includes(origin);
  };

  const isPrefixValid = (eventPrefix: string) => {
    return Object.keys(getAuthorizedOrigins()).includes(eventPrefix);
  };

  const handlers: { [key: string]: Function | PostMessageHandlerInterface } =
    {};

  const addHandler: AddHandler = (type, callback) => {
    handlers[type] = callback;
  };

  const respondFnFactory = (messageEventData: MessageEventData) => {
    const programData = getProgramDataByIframeRoute(messageEventData.fromPath);
    const responseObject = messageEventData.clone();
    return (data: object | string) => {
      responseObject.data = data;
      const { sendMessageToProgramFrame } = useMessageSender();
      sendMessageToProgramFrame(responseObject, programData);
    };
  };

  const parseMessageData = (payload: string): MessageEventData => {
    return MessageEventData.factory(payload);
  };

  const handleMessageEvent = (event: MessageEvent) => {
    const { debugModeLog, debugModeError } = useDebugLogging();
    if (typeof event.data !== 'string') {
      debugModeError('Invalid event::', event.data);
      return;
    }

    const messageData = parseMessageData(event.data);
    if (!isParentPathValid(messageData.fromPath)) {
      return;
    }

    if (!isOriginValid(event.origin)) {
      debugModeError(`Unknown origin ${event.origin}`, name, messageData);
      return;
    }

    if (!isPrefixValid(messageData.origin)) {
      debugModeError(`Unknown prefix ${messageData.origin}`, name, messageData);
      return;
    }

    if (messageData.type in handlers) {
      debugModeLog('Received message event with payload', name, messageData);
      const callback = handlers[messageData.type];

      if (
        typeof callback === 'object' &&
        typeof callback.handle === 'function'
      ) {
        callback.handle(respondFnFactory(messageData), messageData);
      } else if (typeof callback === 'function') {
        callback(messageData);
      }
    }
  };

  onMounted(() => {
    window.addEventListener('message', handleMessageEvent);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('message', handleMessageEvent);
  });

  return {
    addHandler
  };
}
