export const lightFolksTheme = {
  dark: false,
  colors: {
    primary: '#272727',
    'primary-variant': '#ffcb05',
    'surface-tint': '#5f5e5e',
    'on-primary': '#ffffff',
    'on-primary-variant': '#000000',
    'primary-container': '#C8C6C6',
    'primary-variant-container': '#ffcb05',
    'on-primary-container': '#1B1C1C',
    'on-primary-variant-container': '#000000',
    secondary: '#5f5e5e',
    'on-secondary': '#ffffff',
    'secondary-container': '#e9e6e5',
    'on-secondary-container': '#4b4a4a',
    tertiary: '#ffcb05',
    'on-tertiary': '#000000',
    'tertiary-container': '#ffcb05',
    'on-tertiary-container': '#000000',
    error: '#a5000d',
    'on-error': '#ffffff',
    'error-container': '#ea0018',
    'on-error-container': '#ffffff',
    'on-background': '#1c1b1b',
    surface: '#ffffff',
    'on-surface': '#1c1b1b',
    'surface-variant': '#e0e3e3',
    'on-surface-variant': '#444748',
    outline: '#747878',
    'outline-variant': '#c4c7c7',
    shadow: '#000000',
    scrim: '#000000',
    'inverse-surface': '#313030',
    'inverse-on-surface': '#f4f0ef',
    'inverse-primary': '#c8c6c6',
    'inverse-primary-variant': '#c8c6c6',
    'primary-fixed': '#e4e2e1',
    'primary-variant-fixed': '#e4e2e1',
    'on-primary-fixed': '#1b1c1c',
    'on-primary-variant-fixed': '#1b1c1c',
    'primary-fixed-dim': '#c8c6c6',
    'primary-variant-fixed-dim': '#c8c6c6',
    'on-primary-fixed-variant': '#474747',
    'on-primary-variant-fixed-variant': '#474747',
    'secondary-fixed': '#e5e2e1',
    'on-secondary-fixed': '#1c1b1b',
    'secondary-fixed-dim': '#c9c6c5',
    'on-secondary-fixed-variant': '#474646',
    'tertiary-fixed': '#ffe08d',
    'on-tertiary-fixed': '#241a00',
    'tertiary-fixed-dim': '#f2c000',
    'on-tertiary-fixed-variant': '#000000',
    'surface-dim': '#dcdcdc',
    'surface-bright': '#ffffff',
    'surface-container-lowest': '#ffffff',
    'surface-container-low': '#f6f6f6',
    'surface-container': '#f2f2f2',
    'surface-container-high': '#e9e9e9',
    'surface-container-highest': '#e5e2e1',
    background: '#ffffff',
    success: '#70c82b',
    'success-container': '#ccebb4',
    'on-success': '#ffffff',
    'on-success-container': '#3d3f3f'
  }
};
export const darkFolksTheme = {
  dark: true,
  colors: {
    primary: '#c8c6c6',
    'primary-variant': '#ffcb05',
    'surface-tint': '#c8c6c6',
    'on-primary': '#303030',
    'on-primary-variant': '#000000',
    'primary-container': '#303030',
    'primary-variant-container': '#ffcb05',
    'on-primary-container': '#bfbdbd',
    'on-primary-variant-container': '#000000',
    secondary: '#c9c6c5',
    'on-secondary': '#313030',
    'secondary-container': '#403f3f',
    'on-secondary-container': '#d6d3d3',
    tertiary: '#fff7eb',
    'on-tertiary': '#3d2f00',
    'tertiary-container': '#fac700',
    'on-tertiary-container': '#4a3900',
    error: '#ffb4ab',
    'on-error': '#690005',
    'error-container': '#e90018',
    'on-error-container': '#ffffff',
    'on-background': '#e5e2e1',
    surface: '#141313',
    'on-surface': '#e5e2e1',
    'surface-variant': '#444748',
    'on-surface-variant': '#c4c7c7',
    outline: '#8e9192',
    'outline-variant': '#444748',
    shadow: '#000000',
    scrim: '#000000',
    'inverse-surface': '#e5e2e1',
    'inverse-on-surface': '#313030',
    'inverse-primary': '#5f5e5e',
    'inverse-primary-variant': '#5f5e5e',
    'primary-fixed': '#e4e2e1',
    'primary-variant-fixed': '#e4e2e1',
    'on-primary-fixed': '#1b1c1c',
    'on-primary-variant-fixed': '#1b1c1c',
    'primary-fixed-dim': '#c8c6c6',
    'primary-variant-fixed-dim': '#c8c6c6',
    'on-primary-fixed-variant': '#474747',
    'on-primary-variant-fixed-variant': '#474747',
    'secondary-fixed': '#e5e2e1',
    'on-secondary-fixed': '#1c1b1b',
    'secondary-fixed-dim': '#c9c6c5',
    'on-secondary-fixed-variant': '#474646',
    'tertiary-fixed': '#ffe08d',
    'on-tertiary-fixed': '#241a00',
    'tertiary-fixed-dim': '#f2c000',
    'on-tertiary-fixed-variant': '#584400',
    'surface-dim': '#141313',
    'surface-bright': '#3a3939',
    'surface-container-lowest': '#0e0e0e',
    'surface-container-low': '#1c1b1b',
    'surface-container': '#201f1f',
    'surface-container-high': '#2a2a2a',
    'surface-container-highest': '#353434',
    background: '#141313',
    success: '#70c82b',
    'success-container': '#ccebb4',
    'on-success': '#ffffff',
    'on-success-container': '#3d3f3f'
  }
};
