<template>
  <FHeaderPrimary
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import type { Tab } from '@/components/FTabs.types';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Profil employée',
      note: 'Note au dossier'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Employee Profile',
      organizational: 'Note'
    }
  }
});

const tabs = [
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.NOTE,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.NOTE,
    title: t('note')
  },
  {
    to: EMPLOYEES_ROUTES.EMPLOYEE.ONBOARDING,
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.ONBOARDING,
    title: t('onboarding')
  }
] as Tab[];
</script>
