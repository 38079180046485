<template>
  <FHeaderPrimary
    :add-ability="TIME_MANAGEMENT_ABILITIES.VACATION.CONTROL"
    :title="t('title')"
    @add:click="handleAddClick"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import CONTROL_TYPES from '@/modules/core/constants/controlTypes';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.VACATION.LIST,
    bypassAbility: [TIME_MANAGEMENT_ABILITIES.VACATION.CONTROL]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Vacances'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Vacations'
    }
  }
});

const { handleAddClick } = useTimeManagementList(CONTROL_TYPES.VACATION);
</script>
<style lang="sass" scoped></style>
