<template>
  <FLegacyIframe
    :iframe-route="$route.fullPath + '?' + objectToQueryString($route.params)"
  />
</template>
<script lang="ts" setup>
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import { objectToQueryString } from '@/modules/core/utils/route';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EMPLOYEE.NOTE
  }
});
</script>
