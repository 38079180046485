<template>
  <FHeaderPrimary
    :add-ability="ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.CONTROL"
    :add-route="ADVANCEMENT_ROUTES.CERTIFICATES_CAREERS.CONTROL"
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import ADVANCEMENT_ABILITIES from '@/modules/advancement/constants/abilities';
import type { Tab } from '@/components/FTabs.types';
import ADVANCEMENT_ROUTES from '@/modules/advancement/constants/urls';

definePage({
  meta: {
    ability: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Carrières et certificats',
      careers: 'Carrières',
      certificates: 'Certificats'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Careers and Certificates',
      careers: 'Careers',
      certificates: 'Certificates'
    }
  }
});

const tabs = [
  {
    to: ADVANCEMENT_ROUTES.CERTIFICATES_CAREERS.CERTIFICATES,
    ability: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.CERTIFICATES,
    title: t('certificates')
  },
  {
    to: ADVANCEMENT_ROUTES.CERTIFICATES_CAREERS.CAREERS,
    ability: ADVANCEMENT_ABILITIES.CERTIFICATES_CAREERS.CAREERS,
    title: t('careers')
  }
] as Tab[];

useFirstTabRedirect(ADVANCEMENT_ROUTES.CERTIFICATES_CAREERS.ROOT, tabs);
</script>
