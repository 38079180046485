import { defineStore } from 'pinia';
import { getUserPermissions } from '@/modules/core/services/permissions';

export const usePermissionsStore = defineStore('permissions', {
  state: (): {
    permissions: string[];
  } => ({
    permissions: []
  }),
  getters: {
    canAccess: (state) => (ability: string) => {
      // during implementation of permissions, we need to allow all permissions
      /*const fakePermissions = [
        ...state.permissions
      ];
      return fakePermissions.includes(ability);*/
      //return true;
      return ability === '' ? false : state.permissions.includes(ability);
    }
  },
  actions: {
    async getPermissions() {
      const { data }: { data: { message: string; permissions: string[] } } =
        await getUserPermissions();
      this.permissions = data.permissions.sort();
    }
  }
});
