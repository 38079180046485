<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import ONBOARDING_ABILITIES from '@/modules/onboarding/constants/abilities';

definePage({
  meta: {
    ability: ONBOARDING_ABILITIES.ARRIVALS.CONFIG.TITLES
  }
});
</script>
