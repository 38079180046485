<template>
  <FHeaderPrimary
    :back-route="ADVANCEMENT_ROUTES.TRAINING.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import ADVANCEMENT_ABILITIES from '@/modules/advancement/constants/abilities';
import ADVANCEMENT_ROUTES from '@/modules/advancement/constants/urls';

definePage({
  meta: {
    ability: ADVANCEMENT_ABILITIES.TRAINING.CONTROL
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Formation: Créer une entrée'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Training: Create an entry'
    }
  }
});
</script>
