<template>
  <FHeaderPrimary
    :back-route="EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.LIST
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Modification de compte de dépense'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Expense account'
    }
  }
});
</script>
