import _definePage_default_0 from '/builds/folkshr/hris/spa/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/builds/folkshr/hris/spa/src/pages/[...path].vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/careers.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/certificates.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.control.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/analysis.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/summary.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/tracking.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.admin.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training/index.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training/control/index.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training/control/[edit_field].vue?definePage&vue&lang.tsx'
import _definePage_default_15 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training/request/index.vue?definePage&vue&lang.tsx'
import _definePage_default_16 from '/builds/folkshr/hris/spa/src/modules/advancement/pages/training/request/[edit_field].vue?definePage&vue&lang.tsx'
import _definePage_default_17 from '/builds/folkshr/hris/spa/src/pages/advancement.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from '/builds/folkshr/hris/spa/src/pages/auth.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from '/builds/folkshr/hris/spa/src/modules/company/pages/informations.vue?definePage&vue&lang.tsx'
import _definePage_default_20 from '/builds/folkshr/hris/spa/src/modules/company/pages/standards/employees.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from '/builds/folkshr/hris/spa/src/modules/company/pages/standards/organisational.vue?definePage&vue&lang.tsx'
import _definePage_default_22 from '/builds/folkshr/hris/spa/src/modules/company/pages/standards.vue?definePage&vue&lang.tsx'
import _definePage_default_23 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs/index.vue?definePage&vue&lang.tsx'
import _definePage_default_24 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs/position-id.vue?definePage&vue&lang.tsx'
import _definePage_default_25 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/chart.vue?definePage&vue&lang.tsx'
import _definePage_default_26 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/departments.vue?definePage&vue&lang.tsx'
import _definePage_default_27 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/positions.vue?definePage&vue&lang.tsx'
import _definePage_default_28 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/union.vue?definePage&vue&lang.tsx'
import _definePage_default_29 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure/working-sites.vue?definePage&vue&lang.tsx'
import _definePage_default_30 from '/builds/folkshr/hris/spa/src/modules/company/pages/structure.vue?definePage&vue&lang.tsx'
import _definePage_default_31 from '/builds/folkshr/hris/spa/src/pages/company.vue?definePage&vue&lang.tsx'
import _definePage_default_32 from '/builds/folkshr/hris/spa/src/modules/documents/pages/vault/index.vue?definePage&vue&lang.tsx'
import _definePage_default_33 from '/builds/folkshr/hris/spa/src/modules/documents/pages/vault/configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_34 from '/builds/folkshr/hris/spa/src/pages/documents.vue?definePage&vue&lang.tsx'
import _definePage_default_35 from '/builds/folkshr/hris/spa/src/modules/employees/pages/employee/[id]/note.vue?definePage&vue&lang.tsx'
import _definePage_default_36 from '/builds/folkshr/hris/spa/src/modules/employees/pages/employee/[id]/onboarding.vue?definePage&vue&lang.tsx'
import _definePage_default_37 from '/builds/folkshr/hris/spa/src/modules/employees/pages/employee/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_38 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/index.vue?definePage&vue&lang.tsx'
import _definePage_default_39 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_40 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/create.vue?definePage&vue&lang.tsx'
import _definePage_default_41 from '/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_42 from '/builds/folkshr/hris/spa/src/modules/employees/pages/list.vue?definePage&vue&lang.tsx'
import _definePage_default_43 from '/builds/folkshr/hris/spa/src/pages/employees.vue?definePage&vue&lang.tsx'
import _definePage_default_44 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/index.vue?definePage&vue&lang.tsx'
import _definePage_default_45 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/configurations/index.vue?definePage&vue&lang.tsx'
import _definePage_default_46 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/configurations/config.vue?definePage&vue&lang.tsx'
import _definePage_default_47 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/create.vue?definePage&vue&lang.tsx'
import _definePage_default_48 from '/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_49 from '/builds/folkshr/hris/spa/src/pages/hiring.vue?definePage&vue&lang.tsx'
import _definePage_default_50 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/index.vue?definePage&vue&lang.tsx'
import _definePage_default_51 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations/tasks.vue?definePage&vue&lang.tsx'
import _definePage_default_52 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations/templates.vue?definePage&vue&lang.tsx'
import _definePage_default_53 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations/titles.vue?definePage&vue&lang.tsx'
import _definePage_default_54 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_55 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/index.vue?definePage&vue&lang.tsx'
import _definePage_default_56 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations/tasks.vue?definePage&vue&lang.tsx'
import _definePage_default_57 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations/templates.vue?definePage&vue&lang.tsx'
import _definePage_default_58 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations/titles.vue?definePage&vue&lang.tsx'
import _definePage_default_59 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_60 from '/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/control.vue?definePage&vue&lang.tsx'
import _definePage_default_61 from '/builds/folkshr/hris/spa/src/pages/onboarding.vue?definePage&vue&lang.tsx'
import _definePage_default_62 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/notifications/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_63 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/roles-permissions/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_64 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/active-employees/index.vue?definePage&vue&lang.tsx'
import _definePage_default_65 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/index.vue?definePage&vue&lang.tsx'
import _definePage_default_66 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/config.vue?definePage&vue&lang.tsx'
import _definePage_default_67 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/create.vue?definePage&vue&lang.tsx'
import _definePage_default_68 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_69 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/pw-numbers/index.vue?definePage&vue&lang.tsx'
import _definePage_default_70 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/absences-reasons.vue?definePage&vue&lang.tsx'
import _definePage_default_71 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-types.vue?definePage&vue&lang.tsx'
import _definePage_default_72 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-values.vue?definePage&vue&lang.tsx'
import _definePage_default_73 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/vacation-types.vue?definePage&vue&lang.tsx'
import _definePage_default_74 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management.vue?definePage&vue&lang.tsx'
import _definePage_default_75 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_76 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/bulk.vue?definePage&vue&lang.tsx'
import _definePage_default_77 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/create.vue?definePage&vue&lang.tsx'
import _definePage_default_78 from '/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/edit.vue?definePage&vue&lang.tsx'
import _definePage_default_79 from '/builds/folkshr/hris/spa/src/pages/parameters.vue?definePage&vue&lang.tsx'
import _definePage_default_80 from '/builds/folkshr/hris/spa/src/pages/reports.vue?definePage&vue&lang.tsx'
import _definePage_default_81 from '/builds/folkshr/hris/spa/src/pages/safety.vue?definePage&vue&lang.tsx'
import _definePage_default_82 from '/builds/folkshr/hris/spa/src/pages/simulator.vue?definePage&vue&lang.tsx'
import _definePage_default_83 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/absences/index.vue?definePage&vue&lang.tsx'
import _definePage_default_84 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/calendar.vue?definePage&vue&lang.tsx'
import _definePage_default_85 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/overtimes/index.vue?definePage&vue&lang.tsx'
import _definePage_default_86 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/index.vue?definePage&vue&lang.tsx'
import _definePage_default_87 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/calculations.vue?definePage&vue&lang.tsx'
import _definePage_default_88 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/holidays.vue?definePage&vue&lang.tsx'
import _definePage_default_89 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/legend.vue?definePage&vue&lang.tsx'
import _definePage_default_90 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/projects.vue?definePage&vue&lang.tsx'
import _definePage_default_91 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations.vue?definePage&vue&lang.tsx'
import _definePage_default_92 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.vue?definePage&vue&lang.tsx'
import _definePage_default_93 from '/builds/folkshr/hris/spa/src/modules/time-management/pages/vacations/index.vue?definePage&vue&lang.tsx'
import _definePage_default_94 from '/builds/folkshr/hris/spa/src/pages/time-management.vue?definePage&vue&lang.tsx'
import _definePage_default_95 from '/builds/folkshr/hris/spa/src/pages/unauthorized.vue?definePage&vue&lang.tsx'
import _definePage_default_96 from '/builds/folkshr/hris/spa/src/pages/user-params.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/builds/folkshr/hris/spa/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/builds/folkshr/hris/spa/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/advancement',
    name: '/advancement',
    component: () => import('/builds/folkshr/hris/spa/src/pages/advancement.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'certificates-careers',
        name: '/advancement/certificates-careers',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'careers',
            name: '/advancement/certificates-careers/careers',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/careers.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
  _mergeRouteRecord(
          {
            path: 'certificates',
            name: '/advancement/certificates-careers/certificates',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers/certificates.vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      },
  _definePage_default_5
  ),
  _mergeRouteRecord(
      {
        path: 'certificates-careers/control',
        name: '/advancement/certificates-careers.control',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/certificates-careers.control.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: 'performances',
        name: '/advancement/performances',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'analysis',
            name: '/advancement/performances/analysis',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/analysis.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'summary',
            name: '/advancement/performances/summary',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/summary.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
  _mergeRouteRecord(
          {
            path: 'tracking',
            name: '/advancement/performances/tracking',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances/tracking.vue'),
            /* no children */
          },
  _definePage_default_9
  )
        ],
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: 'performances/admin',
        name: '/advancement/performances.admin',
        component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/performances.admin.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
      {
        path: 'training',
        /* internal name: '/advancement/training' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/advancement/training/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training/index.vue'),
            /* no children */
          },
  _definePage_default_12
  ),
          {
            path: 'control',
            /* internal name: '/advancement/training/control' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/advancement/training/control/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training/control/index.vue'),
                /* no children */
              },
  _definePage_default_13
  ),
  _mergeRouteRecord(
              {
                path: ':edit_field',
                name: '/advancement/training/control/[edit_field]',
                component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training/control/[edit_field].vue'),
                /* no children */
              },
  _definePage_default_14
  )
            ],
          },
          {
            path: 'request',
            /* internal name: '/advancement/training/request' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/advancement/training/request/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training/request/index.vue'),
                /* no children */
              },
  _definePage_default_15
  ),
  _mergeRouteRecord(
              {
                path: ':edit_field',
                name: '/advancement/training/request/[edit_field]',
                component: () => import('/builds/folkshr/hris/spa/src/modules/advancement/pages/training/request/[edit_field].vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          }
        ],
      }
    ],
  },
  _definePage_default_17
  ),
  _mergeRouteRecord(
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/builds/folkshr/hris/spa/src/pages/auth.vue'),
    children: [
      {
        path: 'forgot-password',
        name: '/auth/forgot-password',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/forgot-password.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/login.vue'),
        /* no children */
      },
      {
        path: 'logout',
        name: '/auth/logout',
        component: () => import('/builds/folkshr/hris/spa/src/modules/authentication/pages/logout.vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_18
  ),
  _mergeRouteRecord(
  {
    path: '/company',
    name: '/company',
    component: () => import('/builds/folkshr/hris/spa/src/pages/company.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'informations',
        name: '/company/informations',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/informations.vue'),
        /* no children */
      },
  _definePage_default_19
  ),
  _mergeRouteRecord(
      {
        path: 'standards',
        name: '/company/standards',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/standards.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'employees',
            name: '/company/standards/employees',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/standards/employees.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: 'organisational',
            name: '/company/standards/organisational',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/standards/organisational.vue'),
            /* no children */
          },
  _definePage_default_21
  )
        ],
      },
  _definePage_default_22
  ),
  _mergeRouteRecord(
      {
        path: 'structure',
        name: '/company/structure',
        component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure.vue'),
        children: [
          {
            path: 'chairs',
            /* internal name: '/company/structure/chairs' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/company/structure/chairs/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs/index.vue'),
                /* no children */
              },
  _definePage_default_23
  ),
  _mergeRouteRecord(
              {
                path: 'position-id',
                name: '/company/structure/chairs/position-id',
                component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/chairs/position-id.vue'),
                /* no children */
              },
  _definePage_default_24
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'chart',
            name: '/company/structure/chart',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/chart.vue'),
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: 'departments',
            name: '/company/structure/departments',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/departments.vue'),
            /* no children */
          },
  _definePage_default_26
  ),
  _mergeRouteRecord(
          {
            path: 'positions',
            name: '/company/structure/positions',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/positions.vue'),
            /* no children */
          },
  _definePage_default_27
  ),
  _mergeRouteRecord(
          {
            path: 'union',
            name: '/company/structure/union',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/union.vue'),
            /* no children */
          },
  _definePage_default_28
  ),
  _mergeRouteRecord(
          {
            path: 'working-sites',
            name: '/company/structure/working-sites',
            component: () => import('/builds/folkshr/hris/spa/src/modules/company/pages/structure/working-sites.vue'),
            /* no children */
          },
  _definePage_default_29
  )
        ],
      },
  _definePage_default_30
  )
    ],
  },
  _definePage_default_31
  ),
  _mergeRouteRecord(
  {
    path: '/documents',
    name: '/documents',
    component: () => import('/builds/folkshr/hris/spa/src/pages/documents.vue'),
    children: [
      {
        path: 'vault',
        /* internal name: '/documents/vault' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/documents/vault/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/documents/pages/vault/index.vue'),
            /* no children */
          },
  _definePage_default_32
  ),
  _mergeRouteRecord(
          {
            path: 'configurations',
            name: '/documents/vault/configurations',
            component: () => import('/builds/folkshr/hris/spa/src/modules/documents/pages/vault/configurations.vue'),
            /* no children */
          },
  _definePage_default_33
  )
        ],
      }
    ],
  },
  _definePage_default_34
  ),
  _mergeRouteRecord(
  {
    path: '/employees',
    name: '/employees',
    component: () => import('/builds/folkshr/hris/spa/src/pages/employees.vue'),
    children: [
      {
        path: 'employee',
        /* internal name: '/employees/employee' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: '/employees/employee/[id]',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/employee/[id].vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'note',
                name: '/employees/employee/[id]/note',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/employee/[id]/note.vue'),
                /* no children */
              },
  _definePage_default_35
  ),
  _mergeRouteRecord(
              {
                path: 'onboarding',
                name: '/employees/employee/[id]/onboarding',
                component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/employee/[id]/onboarding.vue'),
                /* no children */
              },
  _definePage_default_36
  )
            ],
          },
  _definePage_default_37
  )
        ],
      },
      {
        path: 'expense-accounts',
        /* internal name: '/employees/expense-accounts' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/employees/expense-accounts/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/index.vue'),
            /* no children */
          },
  _definePage_default_38
  ),
  _mergeRouteRecord(
          {
            path: 'configurations',
            name: '/employees/expense-accounts/configurations',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/configurations.vue'),
            /* no children */
          },
  _definePage_default_39
  ),
  _mergeRouteRecord(
          {
            path: 'create',
            name: '/employees/expense-accounts/create',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/create.vue'),
            /* no children */
          },
  _definePage_default_40
  ),
  _mergeRouteRecord(
          {
            path: 'edit',
            name: '/employees/expense-accounts/edit',
            component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/expense-accounts/edit.vue'),
            /* no children */
          },
  _definePage_default_41
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'list',
        name: '/employees/list',
        component: () => import('/builds/folkshr/hris/spa/src/modules/employees/pages/list.vue'),
        /* no children */
      },
  _definePage_default_42
  )
    ],
  },
  _definePage_default_43
  ),
  _mergeRouteRecord(
  {
    path: '/hiring',
    name: '/hiring',
    component: () => import('/builds/folkshr/hris/spa/src/pages/hiring.vue'),
    children: [
      {
        path: 'requisition',
        /* internal name: '/hiring/requisition' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/hiring/requisition/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/index.vue'),
            /* no children */
          },
  _definePage_default_44
  ),
          {
            path: 'configurations',
            /* internal name: '/hiring/requisition/configurations' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/hiring/requisition/configurations/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/configurations/index.vue'),
                /* no children */
              },
  _definePage_default_45
  ),
  _mergeRouteRecord(
              {
                path: 'config',
                name: '/hiring/requisition/configurations/config',
                component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/configurations/config.vue'),
                /* no children */
              },
  _definePage_default_46
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'create',
            name: '/hiring/requisition/create',
            component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/create.vue'),
            /* no children */
          },
  _definePage_default_47
  ),
  _mergeRouteRecord(
          {
            path: 'edit',
            name: '/hiring/requisition/edit',
            component: () => import('/builds/folkshr/hris/spa/src/modules/hiring/pages/requisition/edit.vue'),
            /* no children */
          },
  _definePage_default_48
  )
        ],
      }
    ],
  },
  _definePage_default_49
  ),
  _mergeRouteRecord(
  {
    path: '/onboarding',
    name: '/onboarding',
    component: () => import('/builds/folkshr/hris/spa/src/pages/onboarding.vue'),
    children: [
      {
        path: 'arrivals',
        /* internal name: '/onboarding/arrivals' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/onboarding/arrivals/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/index.vue'),
            /* no children */
          },
  _definePage_default_50
  ),
  _mergeRouteRecord(
          {
            path: 'configurations',
            name: '/onboarding/arrivals/configurations',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'tasks',
                name: '/onboarding/arrivals/configurations/tasks',
                component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations/tasks.vue'),
                /* no children */
              },
  _definePage_default_51
  ),
  _mergeRouteRecord(
              {
                path: 'templates',
                name: '/onboarding/arrivals/configurations/templates',
                component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations/templates.vue'),
                /* no children */
              },
  _definePage_default_52
  ),
  _mergeRouteRecord(
              {
                path: 'titles',
                name: '/onboarding/arrivals/configurations/titles',
                component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/arrivals/configurations/titles.vue'),
                /* no children */
              },
  _definePage_default_53
  )
            ],
          },
  _definePage_default_54
  )
        ],
      },
      {
        path: 'departures',
        /* internal name: '/onboarding/departures' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/onboarding/departures/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/index.vue'),
            /* no children */
          },
  _definePage_default_55
  ),
  _mergeRouteRecord(
          {
            path: 'configurations',
            name: '/onboarding/departures/configurations',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'tasks',
                name: '/onboarding/departures/configurations/tasks',
                component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations/tasks.vue'),
                /* no children */
              },
  _definePage_default_56
  ),
  _mergeRouteRecord(
              {
                path: 'templates',
                name: '/onboarding/departures/configurations/templates',
                component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations/templates.vue'),
                /* no children */
              },
  _definePage_default_57
  ),
  _mergeRouteRecord(
              {
                path: 'titles',
                name: '/onboarding/departures/configurations/titles',
                component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/configurations/titles.vue'),
                /* no children */
              },
  _definePage_default_58
  )
            ],
          },
  _definePage_default_59
  ),
  _mergeRouteRecord(
          {
            path: 'control',
            name: '/onboarding/departures/control',
            component: () => import('/builds/folkshr/hris/spa/src/modules/onboarding/pages/departures/control.vue'),
            /* no children */
          },
  _definePage_default_60
  )
        ],
      }
    ],
  },
  _definePage_default_61
  ),
  _mergeRouteRecord(
  {
    path: '/parameters',
    name: '/parameters',
    component: () => import('/builds/folkshr/hris/spa/src/pages/parameters.vue'),
    children: [
      {
        path: 'notifications',
        /* internal name: '/parameters/notifications' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/parameters/notifications/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/notifications/pages/index.vue'),
            /* no children */
          },
  _definePage_default_62
  )
        ],
      },
      {
        path: 'roles-permissions',
        /* internal name: '/parameters/roles-permissions' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/parameters/roles-permissions/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/roles-permissions/pages/index.vue'),
            /* no children */
          },
  _definePage_default_63
  )
        ],
      },
      {
        path: 'system-management',
        /* internal name: '/parameters/system-management' */
        /* no component */
        children: [
          {
            path: 'active-employees',
            /* internal name: '/parameters/system-management/active-employees' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/parameters/system-management/active-employees/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/active-employees/index.vue'),
                /* no children */
              },
  _definePage_default_64
  )
            ],
          },
          {
            path: 'companies',
            /* internal name: '/parameters/system-management/companies' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/parameters/system-management/companies/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/index.vue'),
                /* no children */
              },
  _definePage_default_65
  ),
  _mergeRouteRecord(
              {
                path: 'config',
                name: '/parameters/system-management/companies/config',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/config.vue'),
                /* no children */
              },
  _definePage_default_66
  ),
  _mergeRouteRecord(
              {
                path: 'create',
                name: '/parameters/system-management/companies/create',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/create.vue'),
                /* no children */
              },
  _definePage_default_67
  ),
  _mergeRouteRecord(
              {
                path: 'edit',
                name: '/parameters/system-management/companies/edit',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/companies/edit.vue'),
                /* no children */
              },
  _definePage_default_68
  )
            ],
          },
          {
            path: 'pw-numbers',
            /* internal name: '/parameters/system-management/pw-numbers' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/parameters/system-management/pw-numbers/',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/pw-numbers/index.vue'),
                /* no children */
              },
  _definePage_default_69
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'time-management',
            name: '/parameters/system-management/time-management',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'absences-reasons',
                name: '/parameters/system-management/time-management/absences-reasons',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/absences-reasons.vue'),
                /* no children */
              },
  _definePage_default_70
  ),
  _mergeRouteRecord(
              {
                path: 'overtime-types',
                name: '/parameters/system-management/time-management/overtime-types',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-types.vue'),
                /* no children */
              },
  _definePage_default_71
  ),
  _mergeRouteRecord(
              {
                path: 'overtime-values',
                name: '/parameters/system-management/time-management/overtime-values',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/overtime-values.vue'),
                /* no children */
              },
  _definePage_default_72
  ),
  _mergeRouteRecord(
              {
                path: 'vacation-types',
                name: '/parameters/system-management/time-management/vacation-types',
                component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/system-management/pages/time-management/vacation-types.vue'),
                /* no children */
              },
  _definePage_default_73
  )
            ],
          },
  _definePage_default_74
  )
        ],
      },
      {
        path: 'users',
        /* internal name: '/parameters/users' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/parameters/users/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/index.vue'),
            /* no children */
          },
  _definePage_default_75
  ),
  _mergeRouteRecord(
          {
            path: 'bulk',
            name: '/parameters/users/bulk',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/bulk.vue'),
            /* no children */
          },
  _definePage_default_76
  ),
  _mergeRouteRecord(
          {
            path: 'create',
            name: '/parameters/users/create',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/create.vue'),
            /* no children */
          },
  _definePage_default_77
  ),
  _mergeRouteRecord(
          {
            path: 'edit',
            name: '/parameters/users/edit',
            component: () => import('/builds/folkshr/hris/spa/src/modules/parameters/submodules/users/pages/edit.vue'),
            /* no children */
          },
  _definePage_default_78
  )
        ],
      }
    ],
  },
  _definePage_default_79
  ),
  _mergeRouteRecord(
  {
    path: '/reports',
    name: '/reports',
    component: () => import('/builds/folkshr/hris/spa/src/pages/reports.vue'),
    /* no children */
  },
  _definePage_default_80
  ),
  _mergeRouteRecord(
  {
    path: '/safety',
    name: '/safety',
    component: () => import('/builds/folkshr/hris/spa/src/pages/safety.vue'),
    /* no children */
  },
  _definePage_default_81
  ),
  _mergeRouteRecord(
  {
    path: '/simulator',
    name: '/simulator',
    component: () => import('/builds/folkshr/hris/spa/src/pages/simulator.vue'),
    /* no children */
  },
  _definePage_default_82
  ),
  _mergeRouteRecord(
  {
    path: '/time-management',
    name: '/time-management',
    component: () => import('/builds/folkshr/hris/spa/src/pages/time-management.vue'),
    children: [
      {
        path: 'absences',
        /* internal name: '/time-management/absences' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time-management/absences/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/absences/index.vue'),
            /* no children */
          },
  _definePage_default_83
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'calendar',
        name: '/time-management/calendar',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/calendar.vue'),
        /* no children */
      },
  _definePage_default_84
  ),
      {
        path: 'overtimes',
        /* internal name: '/time-management/overtimes' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time-management/overtimes/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/overtimes/index.vue'),
            /* no children */
          },
  _definePage_default_85
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'timesheet',
        name: '/time-management/timesheet',
        component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time-management/timesheet/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/index.vue'),
            /* no children */
          },
  _definePage_default_86
  ),
  _mergeRouteRecord(
          {
            path: 'configurations',
            name: '/time-management/timesheet/configurations',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'calculations',
                name: '/time-management/timesheet/configurations/calculations',
                component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/calculations.vue'),
                /* no children */
              },
  _definePage_default_87
  ),
  _mergeRouteRecord(
              {
                path: 'holidays',
                name: '/time-management/timesheet/configurations/holidays',
                component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/holidays.vue'),
                /* no children */
              },
  _definePage_default_88
  ),
  _mergeRouteRecord(
              {
                path: 'legend',
                name: '/time-management/timesheet/configurations/legend',
                component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/legend.vue'),
                /* no children */
              },
  _definePage_default_89
  ),
  _mergeRouteRecord(
              {
                path: 'projects',
                name: '/time-management/timesheet/configurations/projects',
                component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/timesheet/configurations/projects.vue'),
                /* no children */
              },
  _definePage_default_90
  )
            ],
          },
  _definePage_default_91
  )
        ],
      },
  _definePage_default_92
  ),
      {
        path: 'vacations',
        /* internal name: '/time-management/vacations' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time-management/vacations/',
            component: () => import('/builds/folkshr/hris/spa/src/modules/time-management/pages/vacations/index.vue'),
            /* no children */
          },
  _definePage_default_93
  )
        ],
      }
    ],
  },
  _definePage_default_94
  ),
  _mergeRouteRecord(
  {
    path: '/unauthorized',
    name: '/unauthorized',
    component: () => import('/builds/folkshr/hris/spa/src/pages/unauthorized.vue'),
    /* no children */
  },
  _definePage_default_95
  ),
  _mergeRouteRecord(
  {
    path: '/user-params',
    name: '/user-params',
    component: () => import('/builds/folkshr/hris/spa/src/pages/user-params.vue'),
    /* no children */
  },
  _definePage_default_96
  )
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

