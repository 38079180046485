import { ObjectUtils } from '@sdk/modules/core';

/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject({ a: 1, b: { c: 2 } })
 * Returns:
 *   { a: 1, c: 2 }
 */
export const flattenObject = (
  obj: Record<string, any>
): Record<string, any> => {
  const flattened: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      // Recursively flatten the object and merge it
      const nested = flattenObject(value);
      Object.keys(nested).forEach((nestedKey) => {
        flattened[nestedKey] = nested[nestedKey];
      });
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};

export const extractKeysFromRoute = (route: string) => {
  return route.match(/:(\w+)/g)?.map((value) => value.replace(':', '')) || [];
};

export function extractQueryParams(route: string) {
  const hasQueryParams = route.includes('?');
  if (!hasQueryParams) {
    return {};
  }

  const queryString = route.split('?')[1];
  const urlParams = new URLSearchParams(queryString);
  const newData: any = {};
  for (const [key, value] of urlParams.entries()) {
    if (value.includes('=')) {
      newData[key] = {};
      const postDataPairs = value.split('&');
      postDataPairs.forEach((pair) => {
        const [keyPair, valuePair] = pair.split('=');
        newData[key][keyPair] = decodeURIComponent(valuePair);
      });
    } else {
      newData[key] = value;
    }
  }
  return newData;
}

export const replaceParamsInRoute = (
  route: string,
  params: Record<string, string>
): string => {
  return Object.entries(params).reduce(
    (newRoute, [key, value]) => newRoute.replace(`:${key}`, value),
    route
  );
};

export function objectToQueryString(obj: Record<string, any>): string {
  const queryParams = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (ObjectUtils.isObject(obj[key])) {
        queryParams.push(
          encodeURIComponent(key) +
            '=' +
            encodeURIComponent(objectToQueryString(obj[key]))
        );
      } else {
        queryParams.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
        );
      }
    }
  }
  return queryParams.join('&');
}

export const extractValuesFromRoute = (regexp: RegExp, string: string) => {
  let values: string[] = [];
  const exec = regexp.exec(string);
  if (exec) {
    values = exec.map((param) => param);
    values.shift();
  }
  return values;
};

export const createObjectWithKeysAndValues = (
  keys: string[],
  values: string[]
) => {
  return keys.reduce((acc: Record<string, string>, param, index) => {
    acc[param] = values[index];
    return acc;
  }, {});
};
