import type { PostMessageHandlerInterface } from '@/modules/core/composables/PostMessage/handlers/PostMessageHandlerInterface';
import type { useAuthenticationStore } from '@/modules/authentication/store/authentication';

export class LogoutPostMessageHandler implements PostMessageHandlerInterface {
  constructor(
    private readonly authenticationStore: ReturnType<
      typeof useAuthenticationStore
    >
  ) {}

  async handle(): Promise<void> {
    await this.authenticationStore.logout();
  }
}
