<template>
  <FHeaderPrimary
    :back-route="backRoute"
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import type { Tab } from '@/components/FTabs.types';
import TIME_MANAGEMENT_ABILITIES from '@/modules/time-management/constants/abilities';
import TIME_MANAGEMENT_ROUTES from '@/modules/time-management/constants/urls';

definePage({
  meta: {
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Configuration de la feuille de temps',
      projects: 'Liste des projets',
      holidays: 'Paramètres des jours fériés',
      legend: 'Légende',
      calculations: 'Configuration des calculs'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Timesheet configuration',
      projects: 'Projects List',
      holidays: 'Holidays Settings',
      legend: 'Legend',
      calculations: 'Calculations Configuration'
    }
  }
});

const backRoute = TIME_MANAGEMENT_ROUTES.TIMESHEETS.ROOT;

const tabs = [
  {
    to: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.PROJECTS,
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.PROJECTS,
    title: t('projects')
  },
  {
    to: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.HOLIDAY_SETTINGS,
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.HOLIDAY_SETTINGS,
    title: t('holidays')
  },
  {
    to: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.LEGEND,
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.LEGEND,
    title: t('legend')
  },
  {
    to: TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.CALCULATION,
    ability: TIME_MANAGEMENT_ABILITIES.TIMESHEET.CONFIG.CALCULATION,
    title: t('calculations')
  }
] as Tab[];

useFirstTabRedirect(TIME_MANAGEMENT_ROUTES.TIMESHEETS.CONFIG.ROOT, tabs);
</script>
<style lang="sass" scoped></style>
