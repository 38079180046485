import type { FolksSDKModuleInterface } from '@sdk/interfaces/FolksSDKModuleInterface';
import { ContainerModule } from 'inversify';
import type { AuthenticationServiceInterface } from '@sdk/modules/authentication/interfaces/AuthenticationServiceInterface';
import { AUTHENTICATION_BINDING_TYPES } from '@sdk/modules/authentication/FolksSDKAuthenticationModuleTypes';
import { AuthenticationService } from '@sdk/modules/authentication/services/AuthenticationService';
import { APIProxy } from '@sdk/modules/API/proxies/APIProxy';
import { API_BINDING_TYPES } from '@sdk/modules/API';
import unauthenticatedErrorHandler from '@sdk/modules/authentication/handlers/UnauthenticatedErrorHandler';

export class FolksSDKAuthenticationModule implements FolksSDKModuleInterface {
  getContainerModule(): ContainerModule {
    return new ContainerModule(
      (bind, unbind, isBound, rebind, unbindAsync, onActivation) => {
        bind<AuthenticationServiceInterface>(
          AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface
        ).to(AuthenticationService);

        // add an error handler to the APIProxy to handle refreshing the token
        onActivation<APIProxy>(
          API_BINDING_TYPES.APIProxyInterface,
          (context, apiProxy) => {
            apiProxy.addErrorHandler(unauthenticatedErrorHandler, 401);

            return apiProxy;
          }
        );
      }
    );
  }
}
