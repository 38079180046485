import { getCurrentUser } from '../services/user';
import { defineStore } from 'pinia';
import { useCompanyStore } from '@/modules/core/stores/compagny';
import type { User, UserAPI } from '@/modules/core/stores/user.types';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import { useEmployeeStore } from '@/modules/core/stores/employee';

export const useUserStore = defineStore('user', {
  state: () => ({}) as User,
  actions: {
    async getCurrentUser() {
      const { data } = await getCurrentUser();
      this.formatUser(data.data);
      const companyStore = useCompanyStore();
      companyStore.formatCompany(data.data.company);
      const employeeStore = useEmployeeStore();
      employeeStore.formatEmployee(data.data.employee);
    },
    formatUser(user: UserAPI) {
      this.id = user.id;
      this.email = user.email;
      this.first_name = user.first_name;
      this.last_name = user.last_name;
      this.initials =
        user.nickname ||
        `${user.first_name} ${user.last_name}`
          .replace(/-/g, ' ')
          .split(' ')
          .map((n) => n[0])
          .join('')
          .toUpperCase();
      this.nickname = user.nickname || '';
      this.role = user.role;
      this.language =
        user.language === 2 ? SUPPORTED_LANGUAGES.fr : SUPPORTED_LANGUAGES.en;
      this.is_restricted_to_self = user.is_restricted_to_self;
      this.is_support = user.is_support;
    }
  }
});
