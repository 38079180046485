import ApiFolksEndpointBuilder from '@/modules/core/utils/apiFolks/ApiFolksEndpointBuilder';
import proxy from '@/modules/core/utils/apiFolks/proxy';

const baseEndpoint = `authorization`;

export async function authenticate(email: string, password: string) {
  const response = await proxy.post(
    new ApiFolksEndpointBuilder(`${baseEndpoint}/give`).build(),
    {
      email,
      password,
      with_legacy_session_token: true
    }
  );
  return {
    access_token: response.data.access_token,
    refresh_token: response.data.refresh_token,
    session_token: response.data.session_token,
    expires_in: response.data.expires_in
  };
}

export async function refresh(refresh_token: string) {
  const response = await proxy.post(
    new ApiFolksEndpointBuilder(`${baseEndpoint}/refresh`).build(),
    {
      refresh_token,
      with_legacy_session_token: true
    }
  );
  return {
    access_token: response.data.access_token,
    refresh_token: response.data.refresh_token,
    expires_in: response.data.expires_in,
    session_token: response.data.session_token
  };
}

export async function logout() {
  return proxy.post(
    new ApiFolksEndpointBuilder(`${baseEndpoint}/revoke`).build()
  );
}
