<template>
  <FHeaderPrimary :title="t('title')" />
  <VDataTable
    :headers="headers"
    :items="items"
    hide-default-footer
  >
    <template #[`item.actions`]="{ item }">
      <VBtn
        icon="mdi-pencil"
        @click="redirectToEmployee(item.id)"
      />
    </template>
  </VDataTable>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import type { RouteNamedMap } from 'vue-router/auto';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.LIST
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Liste des employées'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Employees List'
    }
  }
});

const headers = ref([
  {
    title: "Nom de l'employée",
    key: 'name'
  },
  { title: 'Actions', key: 'actions', sortable: false }
]);

const items = ref([
  {
    id: 210060,
    name: 'bdexskizwv, xqvkvzywie'
  },
  {
    id: 218198,
    name: 'bralktqqpc, ruopbvjurf'
  }
]);

const router = useRouter();
const redirectToEmployee = (id: number) => {
  const routeName = EMPLOYEES_ROUTES.EMPLOYEE.ROOT as keyof RouteNamedMap;
  router.push({ name: routeName, params: { id } });
};
</script>
