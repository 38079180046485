<template>
  <FHeaderPrimary
    :back-route="backRoute"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';

definePage({
  meta: {
    ability: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.CONFIG.ADMISSIBLE
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Configuration des comptes de dépense'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Expense accounts configuration'
    }
  }
});

const backRoute = EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.ROOT;
</script>
