<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import COMPANY_ABILITIES from '@/modules/company/constants/abilities';

definePage({
  meta: {
    ability: COMPANY_ABILITIES.STRUCTURE.WORKINGSITES
  }
});
</script>
