<template>
  <FLayoutDefault>
    <FHeaderPrimary :title="t('title')" />
    <FLayoutContentForIframe />
  </FLayoutDefault>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';

definePage({
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Paramètres utilisateur'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'User settings'
    }
  }
});
</script>
