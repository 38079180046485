const EMPLOYEES_ROOT = 'employees';
const CONFIG_ROOT = 'config';

const EMPLOYEE_ROOT = `${EMPLOYEES_ROOT}:employee`;
const EXPENSE_ACCOUNTS_ROOT = `${EMPLOYEES_ROOT}:expense-account`;
const EXPENSE_ACCOUNT_CONFIG_ROOT = `${EXPENSE_ACCOUNTS_ROOT}:${CONFIG_ROOT}`;

const EMPLOYEES_ABILITIES = {
  ANY: `${EMPLOYEES_ROOT}`,
  LIST: `${EMPLOYEES_ROOT}:list`,
  EMPLOYEE: {
    ANY: EMPLOYEE_ROOT,
    NOTE: `${EMPLOYEE_ROOT}:note`,
    ONBOARDING: `${EMPLOYEE_ROOT}:onboarding`
  },
  EXPENSE_ACCOUNTS: {
    ANY: EXPENSE_ACCOUNTS_ROOT,
    LIST: `${EXPENSE_ACCOUNTS_ROOT}:analysis`,
    REQUEST: `${EXPENSE_ACCOUNTS_ROOT}:request`,
    CONFIG: {
      ANY: EXPENSE_ACCOUNT_CONFIG_ROOT,
      ADMISSIBLE: `${EXPENSE_ACCOUNT_CONFIG_ROOT}:admissible`
    }
  }
};

export default EMPLOYEES_ABILITIES;
