import LEGACY_TYPE from '@/modules/core/constants/legacyType';
import type { Program } from '@/modules/core/utils/mapping/index.types';
import NOTIFICATIONS_ABILITIES from '@/modules/parameters/submodules/notifications/constants/abilities';
import NOTIFICATIONS_ROUTES from '@/modules/parameters/submodules/notifications/constants/urls';

const NOTIFICATIONS_MAPPING = [
  {
    name: NOTIFICATIONS_ABILITIES.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 507000,
    module: NOTIFICATIONS_ABILITIES.ANY,
    routeName: NOTIFICATIONS_ROUTES.ROOT
  }
] as Program[];

export default NOTIFICATIONS_MAPPING;
