<template>
  <FLayoutDefault>
    <FLegacyIframe :iframe-route="$route.fullPath" />
  </FLayoutDefault>
</template>
<script lang="ts" setup>
definePage({
  meta: {
    needAuth: true
  }
});
</script>
<style lang="sass" scoped></style>
