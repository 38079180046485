<template>
  <FHeaderPrimary :title="t('title')" />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import ROLES_PERMISSIONS_ABILITIES from '@/modules/parameters/submodules/roles-permissions/constants/abilities';

definePage({
  meta: {
    ability: ROLES_PERMISSIONS_ABILITIES.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Gestion des rôles'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Roles management'
    }
  }
});
</script>
