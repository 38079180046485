<template>
  <FLegacyIframe :iframe-route="$route.fullPath" />
</template>
<script lang="ts" setup>
import SYSTEM_MANAGEMENT_ABILITIES from '@/modules/parameters/submodules/system-management/constants/abilities';

definePage({
  meta: {
    ability: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.VACATION_TYPES
  }
});
</script>
