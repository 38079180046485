import type { Program } from '@/modules/core/utils/mapping/index.types';
import LEGACY_TYPE from '@/modules/core/constants/legacyType';
import EMPLOYEES_ROUTES from '@/modules/employees/constants/urls';
import EMPLOYEES_ABILITIES from '@/modules/employees/constants/abilities';

const EMPLOYEE_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.NOTE,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    isLegacyModal: true,
    legacyModalUrl: 'urio-modal.php?dialog=note&em_id=:id',
    programId: 0,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.NOTE
  },
  {
    name: EMPLOYEES_ABILITIES.EMPLOYEE.ONBOARDING,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    isLegacyModal: true,
    legacyModalUrl: 'urio-modal.php?dialog=onboarding&em_id=:id',
    programId: 0,
    editKey: 'id',
    module: EMPLOYEES_ABILITIES.EMPLOYEE.ANY,
    routeName: EMPLOYEES_ROUTES.EMPLOYEE.ONBOARDING
  }
] as Program[];

const EXPENSE_ACCOUNTS_MAPPING = [
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.LIST,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 201110,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.ROOT
  },
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.CONFIG.ANY,
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505110,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.CONFIG.ROOT
  },
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.REQUEST + ':edit',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 405000,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.EDIT
  },
  {
    name: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.REQUEST + ':create',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 405000,
    module: EMPLOYEES_ABILITIES.EXPENSE_ACCOUNTS.ANY,
    routeName: EMPLOYEES_ROUTES.EXPENSE_ACCOUNTS.CREATE
  }
] as Program[];

const EMPLOYEES_MAPPING = [
  ...EMPLOYEE_MAPPING,
  ...EXPENSE_ACCOUNTS_MAPPING
] as Program[];

export default EMPLOYEES_MAPPING;
