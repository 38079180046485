import PARAMETERS_ABILITIES_ROOT from '@/modules/parameters/constants/abilities';

const SYSTEM_MANAGEMENT_ABILITIES_ROOT = `${PARAMETERS_ABILITIES_ROOT}:system-management`;

const COMPANIES_ROOT = `${SYSTEM_MANAGEMENT_ABILITIES_ROOT}:companies`;
const TIME_MANAGEMENT_ROOT = `${SYSTEM_MANAGEMENT_ABILITIES_ROOT}:time-management`;

const SYSTEM_MANAGEMENT_ABILITIES = {
  ANY: SYSTEM_MANAGEMENT_ABILITIES_ROOT,
  COMPANIES: {
    ANY: COMPANIES_ROOT,
    LIST: `${COMPANIES_ROOT}:list`,
    CREATE: `${COMPANIES_ROOT}:create`,
    EDIT: `${COMPANIES_ROOT}:edit`,
    CONFIG: `${COMPANIES_ROOT}:config`
  },
  ACTIVE_EMPLOYEES: {
    ANY: `${SYSTEM_MANAGEMENT_ABILITIES_ROOT}:active-employees`
  },
  PW_NUMBERS: {
    ANY: `${SYSTEM_MANAGEMENT_ABILITIES_ROOT}:pw-numbers`
  },
  TIME_MANAGEMENT: {
    ANY: TIME_MANAGEMENT_ROOT,
    ABSENCES_REASONS: `${TIME_MANAGEMENT_ROOT}:absences-reasons`,
    VACATION_TYPES: `${TIME_MANAGEMENT_ROOT}:vacation-types`,
    OVERTIME_TYPES: `${TIME_MANAGEMENT_ROOT}:overtime-types`,
    OVERTIME_VALUES: `${TIME_MANAGEMENT_ROOT}:overtime-values`
  }
};

export default SYSTEM_MANAGEMENT_ABILITIES;
