<template>
  <FHeaderPrimary
    :configuration-ability="HIRING_ABILITIES.REQUISITIONS.CONFIG.ANY"
    :configuration-route="HIRING_ROUTES.REQUISITIONS.CONFIG.ROOT"
    :title="t('title')"
  />
  <FLayoutContentForIframe />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import HIRING_ABILITIES from '@/modules/hiring/constants/abilities';
import HIRING_ROUTES from '@/modules/hiring/constants/urls';

definePage({
  meta: {
    ability: HIRING_ABILITIES.REQUISITIONS.LIST,
    bypassAbility: [HIRING_ABILITIES.REQUISITIONS.CONFIG.ANY]
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Réquisition de personnel'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Job Requisition'
    }
  }
});
</script>
