enum SUPPORTED_LANGUAGES {
  en = 'en-US',
  fr = 'fr-CA'
}

export const SUPPORTED_LANGUAGES_VALUES: Array<SUPPORTED_LANGUAGES> =
  Object.values(SUPPORTED_LANGUAGES);

export const SUPPORTED_LANGUAGES_UNILANG_TEXT = {
  [SUPPORTED_LANGUAGES.en]: 'English',
  [SUPPORTED_LANGUAGES.fr]: 'Français'
};

export default SUPPORTED_LANGUAGES;
