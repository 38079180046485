/**
 * Automatic routes for `./src/pages/*.vue`
 */
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized
} from 'vue-router';

import { routes } from 'vue-router/auto-routes';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import { useUserStore } from '@/modules/core/stores/user';
import { fillPendo } from '@/plugins/pendo';
import { fillBugsnag } from '@/plugins/bugsnag';
import { useFeatureFlagsStore } from '@/modules/core/stores/featureFlags';
import { usePermissionsStore } from '@/modules/core/stores/permissions';
import { setI18nLanguage } from '@/plugins/vueI18n';
import AUTHENTICATION_ROUTES from '@/modules/authentication/constants/urls';
import BASE_ROUTES from '@/constants/urls';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: import.meta.env.VITE_APP_SPA_SIMULATOR
    ? routes
    : routes.filter((route) => !route.meta?.hideInProd)
});

const getAccessTokenFromLocalStorage = () => {
  const authStore = useAuthenticationStore();

  if (!authStore.authData.access_token) {
    authStore.getFromLocalStorage();
  }

  return !!authStore.authData.access_token;
};

const canAccessPage = async (to: RouteLocationNormalized) => {
  if (to.meta.ability) {
    const { canAccess } = usePermissionsStore();
    // @ts-ignore
    return canAccess(to.meta.ability);
  }
  return true;
};

const checkForByPassAbility = (bypassAbility: string[] = []) => {
  const { canAccess } = usePermissionsStore();
  let bypass = false;
  bypassAbility.forEach((ability) => {
    if (canAccess(ability)) {
      bypass = true;
      return true;
    }
  });
  return bypass;
};

const hasToGetData = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  return !!(from.name === undefined || from.meta.isAuthPages);
};

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const hasAccessToken = getAccessTokenFromLocalStorage();
    if (to.meta.needAuth) {
      if (!hasAccessToken) {
        return AUTHENTICATION_ROUTES.LOGIN;
      }
    }

    if (to.meta.isAuthPages) {
      if (hasAccessToken) {
        return BASE_ROUTES.HOME;
      }
    }

    if (to.meta.needAuth && hasAccessToken) {
      const userStore = useUserStore();
      const permissionsStore = usePermissionsStore();
      const featureFlagsStore = useFeatureFlagsStore();
      if (hasToGetData(to, from)) {
        await Promise.all([
          userStore.getCurrentUser(),
          permissionsStore.getPermissions(),
          featureFlagsStore.getFeatureFlags()
        ]);
        setI18nLanguage(userStore.language);
        fillBugsnag();
        fillPendo();
      }

      const hasAccessPage = await canAccessPage(to);
      //@ts-ignore
      const { bypassAbility }: { bypassAbility: string[] } = to.meta;

      if (checkForByPassAbility(bypassAbility)) {
        return true;
      } else {
        if (!hasAccessPage) {
          return BASE_ROUTES.UNAUTHORIZED;
        }
      }
    }
    return true;
  }
);

export default router;
