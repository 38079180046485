<template>
  <FHeaderPrimary
    :title="t('title')"
    :tabs="tabs"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import type { Tab } from '@/components/FTabs.types';
import SYSTEM_MANAGEMENT_ABILITIES from '@/modules/parameters/submodules/system-management/constants/abilities';
import SYSTEM_MANAGEMENT_ROUTES from '@/modules/parameters/submodules/system-management/constants/urls';

definePage({
  meta: {
    ability: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Gestion du temps',
      absences: 'Raisons d’absences',
      vacationTypes: 'Types de vacances',
      overtimeTypes: 'Types de temps supplémentaire',
      overtimeValues: 'Valeurs de temps supplémentaire'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Time management',
      absences: 'Reasons for absence',
      vacationTypes: 'Types of vacations',
      overtimeTypes: 'Types of overtime',
      overtimeValues: 'Overtime values'
    }
  }
});

const tabs = [
  {
    to: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.ABSENCES_REASONS,
    ability: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.ABSENCES_REASONS,
    title: t('absences')
  },
  {
    to: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.VACATION_TYPES,
    ability: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.VACATION_TYPES,
    title: t('vacationTypes')
  },
  {
    to: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.OVERTIME_TYPES,
    ability: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.OVERTIME_TYPES,
    title: t('overtimeTypes')
  },
  {
    to: SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.OVERTIME_VALUES,
    ability: SYSTEM_MANAGEMENT_ABILITIES.TIME_MANAGEMENT.OVERTIME_VALUES,
    title: t('overtimeValues')
  }
] as Tab[];

useFirstTabRedirect(SYSTEM_MANAGEMENT_ROUTES.TIME_MANAGEMENT.ROOT, tabs);
</script>
