import type { Hook, Module } from '@/modules/helpers.types';

export async function registerModules(
  modules: Record<string, () => Promise<unknown>>
): Promise<void> {
  for (const path in modules) {
    const module = (await modules[path]()) as Module;
    if (typeof module.initialize === 'function') {
      module.initialize();
    } else {
      throw new Error(`Module ${path} does not have an initialize function`);
    }
  }
}

export async function registerHooks(
  hooks: Record<string, () => Promise<unknown>>
): Promise<void> {
  for (const path in hooks) {
    const hook = (await hooks[path]()) as Hook;
    if (typeof hook.bind === 'function') {
      hook.bind();
    } else {
      throw new Error(`hook ${path} does not have an bind function`);
    }
  }
}
