<template>
  <FHeaderPrimary
    :tabs="tabs"
    :title="t('title')"
  />
  <FLayoutContentForTabs />
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import SUPPORTED_LANGUAGES from '@/modules/core/constants/languages';
import type { Tab } from '@/components/FTabs.types';
import COMPANY_ROUTES from '@/modules/company/constants/urls';
import COMPANY_ABILITIES from '@/modules/company/constants/abilities';

definePage({
  meta: {
    ability: COMPANY_ABILITIES.STRUCTURE.ANY
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: "Structure d'organisation",
      workingSites: "Site d'opération",
      departments: 'Département',
      positions: "Titres d'emploi",
      union: 'Syndicats',
      chart: 'Organigramme',
      chairs: 'Chaise'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Organization Structure',
      workingSites: 'Working Sites',
      departments: 'Departments',
      positions: 'Edit Position',
      union: 'Union',
      chart: 'Organization chart',
      chairs: 'Headcount'
    }
  }
});

const tabs = [
  {
    to: COMPANY_ROUTES.STRUCTURE.WORKINGSITES,
    ability: COMPANY_ABILITIES.STRUCTURE.WORKINGSITES,
    title: t('workingSites')
  },
  {
    to: COMPANY_ROUTES.STRUCTURE.DEPARTMENTS,
    ability: COMPANY_ABILITIES.STRUCTURE.DEPARTMENTS,
    title: t('departments')
  },
  {
    to: COMPANY_ROUTES.STRUCTURE.POSITIONS,
    ability: COMPANY_ABILITIES.STRUCTURE.POSITIONS,
    title: t('positions')
  },
  {
    to: COMPANY_ROUTES.STRUCTURE.UNION,
    ability: COMPANY_ABILITIES.STRUCTURE.UNION,
    title: t('union')
  },
  {
    to: COMPANY_ROUTES.STRUCTURE.CHART,
    ability: COMPANY_ABILITIES.STRUCTURE.CHART,
    title: t('chart')
  },
  {
    to: COMPANY_ROUTES.STRUCTURE.CHAIRS,
    ability: COMPANY_ABILITIES.STRUCTURE.CHAIRS,
    title: t('chairs')
  }
] as Tab[];

useFirstTabRedirect(COMPANY_ROUTES.STRUCTURE.ROOT, tabs);
</script>
